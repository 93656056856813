import React, { useEffect, useState } from "react";
import "./cost.css";
import Img from "../assets/images/dis.png"
import Img1 from "../assets/images/dis1.png"
import Img2 from "../assets/images/dis2.png"
import Img3 from "../assets/images/dis3.png"
import { useDispatch, useSelector } from "react-redux";
import { getCurrencydata } from "../redux/slices/settingSlice";
import OrderShippingAddress from "../components/UI/OrderShippingAddress";
import OrderShipping from "./Checkout/OrderShipping";
import Cart from "./Cart";
import { useQuery } from "react-query";
import axiosClient from "../axios-client";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function CostBreakdown(props) {
    const {totalAmount,cartitem} = props;
    
    const subtotal = useSelector((state) => state.cart.totalAmount);
    const shippingCostget = useSelector((state) => state.setting.shippingCost);
    const shippingCostdata = subtotal > 0 ? shippingCostget : 0;
    const cartItems = useSelector((state) => state.cart.cartItems);
    const [shippingInfo, setShippingInfo] = useState([]);
    const [activeItem, setActiveItem] = useState(0);
    const [activicon,setActiveicon]=useState(0);

    const dispatch = useDispatch();
    const currency = useSelector((state) => state.setting.currency);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getCurrencydata())
    }, [])

    const p = {
        color: "#007bff",
        fontSize: "14px",
        fontWeight: "600",
    }

    const p1 = {
        fontSize: "14px",
        fontWeight: "600",
    }
    const shippingAddress = (address) => {
        setShippingInfo(address)
        // setActiveItem(2);
    }

    const orderStepAction = (data) => {
        setActiveItem(data)
    }

    useEffect(() => {

    }, [activeItem])
    //console.log("activeItem",activeItem);
    props.checkoutvalue(activeItem)

    const deliveryCost = cartItems.reduce((total, item) => total + item.delivery_cost, 0);
    const installation_cost = cartItems.reduce((total, item) => total + item.installation_cost, 0);
    const isLogin = useSelector((state) => state.user.isLogin)
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const guestInfo = useSelector((state) => state.user.guestInfo);

// console.log('cartitem',cartitem);


const getTotalValues = (items) => {
    if (!Array.isArray(items)) {
        return {
            Price: 0,
            totalInstallationCost: 0,
            totalDeliveryCost: 0,
            totalPrice: 0,
        };
    }
    return items.reduce(
        (totals, item) => {
            // Convert price to number
            const price = parseFloat(item.price.replace('.', '')) || 0;

            totals.Price += price;
            totals.totalInstallationCost += item.installation_cost;
            totals.totalDeliveryCost += item.delivery_cost;
            totals.totalPrice += item.totalPrice;

            return totals;
        },
        {
            Price: 0,
            totalInstallationCost: 0,
            totalDeliveryCost: 0,
            totalPrice: 0,
        }
    );
};


  const totals = getTotalValues(cartitem);
  const getallprice = (totals.totalPrice) + ( totals.totalInstallationCost)+(totals.totalDeliveryCost)

//   console.log("Price:",getallprice);


    const cardStepSet = (data) => {
        if (data > 0 && !isLogin && guestInfo === null) {
          toast.error('Login First')
          return false;
        }
        // if (data > 0) {
        //   if (totalQuantity <= 0) {
        //     toast.error('First add to cart')
        //     return false;
        //   }
        // }
        setActiveicon(data)
        props.action(data)
      }

    //   console.log("activicon",activicon);


      useEffect(() => {
        cardStepSet(props.activitem);
        // console.log(props.activitem);
      }, [])

//console.log("deliveryCost",deliveryCost);
    return (
        <div className="container">
            <div className="card p-4 w-100 m-0">
                <h2 className="text-primary">{currency} ${parseFloat(getallprice).toFixed(2)}</h2>
                <h5>Cost Breakdown</h5>
                <hr />
                <div className="row d-flex" style={{ display: "flex" }}>
                    <div className="col-6">
                        <p style={p}>Product Cost</p>
                        <p style={p}>Delivery Cost</p>
                        <p style={p}>Installation Cost</p>
                        <p style={p}>Rental Cost</p>
                        <p style={p}>Labour Cost</p>
                        <p style={p}>Travel Rate</p>
                        <p style={p}>Sales Tax</p>
                        <p style={p}>Damage Deposit</p>
                    </div>
                    <div className="col-6 text-right costp">
                        <p style={p1}>${parseFloat(totals.totalPrice).toFixed(2)}</p>
                        <p style={p1}>${isNaN(totals.totalDeliveryCost) ? 0 : totals.totalDeliveryCost}</p>
                        <p style={p1}>${isNaN(totals.totalInstallationCost) ? 0 : totals.totalInstallationCost}</p>
                        <p style={p1}>$0</p>
                        <p style={p1}>$0</p>
                        <p style={p1}>$0</p>
                        <p style={p1}>$0</p>
                        <p style={p1}>$0</p>
                        <p style={p1}>$0</p>
                    </div>
                </div>
                {/* <div className="mt-3">
                    <h5>
                        Delivery Location{" "}
                        <a href="#" className="text-primary">
                            Change
                        </a>
                    </h5>
                    <p style={p}>Address: 352st New Ydhbj</p>
                    <p style={p}>City: Miami</p>
                    <p style={p}>Zip Code: 31001</p>
                </div> */}
                <div className="mt-3">
                
                    <button className="btn btn-block">

                    {activicon === 1 ? null : (
                cartitem.length > 0 && activicon !== 1 ? (
                  <button className="buy__btn w-100" onClick={()=>cardStepSet(1)}>
                     Checkout
                  </button>
                ) : null
              )}
 {activicon === 1 ? null : (
                cartitem.length > 0 && activicon !== 1 ? (
                <button className="btn btn-outline-primary btn-block mt-2">
                <Link to="/all/product" style={{ textDecoration: 'none', color: 'inherit' }}> Add Extra Equipment </Link>
              </button>
              ) : null
            )}
                        {/* {
                            <Cart action={orderStepAction} activeItem={activeItem} /> 
                        } */}
                    </button>
                    
                </div>
                <div className="mt-3 text-center">
                    {/* <img src={Img} alt="Discover" className="mx-2 common_img" />
                    <img src={Img1} alt="American Express" className="mx-2 common_img" />
                    <img src={Img2} alt="Visa" className="mx-2 common_img" />
                    <img src={Img3} alt="Mastercard" className="mx-2 common_img" /> */}
                </div>
            </div>
        </div>
    );
}

export default CostBreakdown;