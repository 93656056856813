import React from 'react'
import { useState, useEffect } from 'react';
import './PrePackage.css'
import PackageProduct from './PackageProduct';
import axiosClient from '../axios-client';
import { Config } from '../Config';
import Spinner from '../components/Spninnerloader/Spinner';
import Helmet from '../components/Helmet/Helmet';

function PrePackage() {

    const [activeTab, setActiveTab] = useState(0);
    const [packageData, setPackageData] = useState([]);
    const [packegaId, setPackageId] = useState();
    const [loading, setLoading] = useState(true);
    
    console.log("packegaId", packegaId)

    const handleTabClick = (tabNumber, id) => {
        setActiveTab(tabNumber);
        setPackageId(id);
       
    };

    const PackageData = async  () => {
        try {
            setLoading(true)
            const response = await axiosClient.get('event/category/get')
            setPackageData(response.data.data)
            if (response.data.data.length > 0) {
                
                setPackageId(response.data.data[0].id);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching package data:', error);
        }
    }

    useEffect(() => {
        PackageData()
    }, [])

    const [premadPackage, setPremadPackage]= useState('');

    useEffect(() => {
        axiosClient.get('seo/pre_made').then(({ data }) => {
          setPremadPackage(data.data);
        });
    
      }, [])

  return (
        <Helmet title={premadPackage && premadPackage.seo_title} >
        <meta name="description" content={premadPackage && premadPackage.seo_meta_desc} />
        <meta name="keywords" content={premadPackage && premadPackage.seo_meta_name} />
        <div className='container'>

         { loading ? (<Spinner/>):(<div className='row'>
            <div className='package_heading'>
                <h1>
                    What kind of event are you organizing?
                </h1>
            </div>
            <div className='jkbihkl'>
                <div className="tab-menu">
                    {packageData.map((item, index) => (
                        <div
                            key={index}
                            className={`tab ${activeTab === index ? 'active' : ''}`}
                            onClick={() => handleTabClick(index, item.id)}
                            style={{cursor:"pointer"}}
                        >
                            <div className='package-img'>
                                <img loading='lazy' src={Config.baseUrl + "/" + item.cate_image} alt="" />
                                <div className='package-text'>{item.cate_name}</div>
                            </div>
                        </div>
                    ))} 
                    
                </div>
                <div className="tab-content">
                {packageData.map((item, index) => (
                    <div key={index} style={{ display: activeTab === index ? 'block' : 'none' }}>
                        <PackageProduct packegaId={item.id} />
                    </div>
                ))}
                </div>
            </div>
        </div>)}
    </div>
    </Helmet>
  )
}

export default PrePackage