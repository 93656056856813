import React, { useState } from 'react';
import Helmet from '../components/Helmet/Helmet';
import { Link, useHistory, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import '../styles/authentications.css';
import axiosClient from '../axios-client';
import { toast } from "react-toastify";
import { userAction } from '../redux/slices/userSlice';


const Signup = () => {
  const newsletter = localStorage.getItem('newlatterEmail');
  const [username, setUserName] = useState();
  const [phone, setPhone] = useState()
  const [email, setEmail] = useState(newsletter);
  const [password, setPassword] = useState();
  const [passwordConfirmation, setpasswordConfirmation] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const submitregForm = (e) => {
    e.preventDefault()
    var data = {
      name: username,
      email: email,
      phone: phone,
      password: password,
      password_confirmation: passwordConfirmation
    }
    axiosClient
      .post('/signup', data)
      .then(({ data }) => {

        if (data.status == 422) {
          console.log(data.msg)
          toast.error(data.msg, { theme: "colored" });
        }
        if (data.status == 200) {
          let infodata = {
            token: data.token,
            userInfo: data.user,
          }
          dispatch(userAction.setLoginInfo(infodata));
          toast.success('Registration  successfully completed ')
          navigate('/')

        }


        localStorage.setItem('ACCESS_TOKEN', data.token)
        localStorage.setItem('USER_DATA', JSON.stringify(data.user))
      });
  }

  return (
    <Helmet title="Signup">
      <section>
        <Container>
          <Row>
            {/* <Col lg='12' className='login-feture py-5'>
                    <h3 className='fw-bold fs-4 mb-4 text-center'> Sign Up </h3>
                    <div className='d-flex justify-content-center'>
                      <Form className='auth__form' onSubmit={(e)=>submitregForm(e)}>
                          <FormGroup className='form__group'>
                              <Input 
                                type="text"
                                required
                                placeholder='Username'
                                value={username} 
                                onChange={(e) => setUserName(e.target.value)}
                              />
                          </FormGroup>
                          <FormGroup className='form__group'>
                              <Input 
                                type="email"
                                placeholder='Enter Your email'
                                value={email}
                                onChange={(e)=> setEmail(e.target.value)}
                              />
                          </FormGroup>
                          <FormGroup className='form__group'>
                              <Input 
                                type="text"
                                placeholder='Mobile Number'
                                value={phone}
                                onChange={(e)=> setPhone(e.target.value)}
                              />
                          </FormGroup>
                          <FormGroup className='form__group'>
                            <Input 
                              type="password" 
                              placeholder='Enter Your Password'
                              value={password} 
                              minLength={6}
                              onChange={(e)=> setPassword(e.target.value)} 
                            />
                          </FormGroup>

                          <FormGroup className='form__group'>
                            <Input 
                              type="password" 
                              placeholder='Enter Your Confirm Password'
                              value={passwordConfirmation} 
                              minLength={6}
                              
                              onChange={(e)=> setpasswordConfirmation(e.target.value)} 
                            />
                          </FormGroup>

                          <FormGroup className='form__group'>
                              <button type='submit' class="auth__btn"> Create an Account</button>
                          </FormGroup>
                          <p className='text-black'> 
                              Alreaady have an account ? {""}  
                              <Link className= 'text-black fw-bold' to="/login"> Login </Link>
                          </p>
                      </Form>
                    </div>
                </Col> */}

            <div className="signup-page">
              <div className="signup-container">
                <div className="signup-form-container">
                  <div className="signup-glass-effect">
                    <h2>Create an Account</h2>
                    <form onSubmit={(e) => submitregForm(e)}>
                      <div className="input-container">
                        <input value={username} style={{color:"#000"}} onChange={(e) => setUserName(e.target.value)} type="text" placeholder="Username" required />
                      </div>
                      <div className="input-container">
                        <input
                          type="email"
                          placeholder="Email"
                          required
                          style={{color:"#000"}}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="input-container">
                        <input
                          type="tel"
                          placeholder="Mobile Number"
                          required
                          style={{color:"#000"}}
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="input-container">
                        <input
                          type="password"
                          placeholder="Password"
                          required
                          style={{color:"#000"}}
                          value={password}
                          minLength={6}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="input-container">
                        <input
                          type="password"
                          placeholder="Confirm Password"
                          required
                          style={{color:"#000"}}
                          value={passwordConfirmation}
                          minLength={6}
                          onChange={(e) => setpasswordConfirmation(e.target.value)}
                        />
                      </div>
                      <button type="submit" className="signup-btn">
                        Create Account
                      </button>
                    </form>

                    <div className="signup-options">
                      <p>
                        Already have an account? <Link to={"/login"} className="forgot-password">Login</Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="signup-image-container">
                  <img
                    src="https://img.freepik.com/premium-vector/illustration-sign-up-log-wireframe-idea-showcasing-various-ui-elements_1278800-10899.jpg?w=826"
                    alt="Signup Illustration"
                    className="signup-image"
                  />
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </Helmet>
  )
}

export default Signup;