import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/UI/CommonSection'
import axiosClient from '../axios-client'
import './faq.css'

const Faq = () => {

    const [faq, setFaq] = useState();
    const [faqs, setFaqs] = useState("");

    useEffect(()=>{
        axiosClient.get("faq/list").then((data)=>{
            setFaq(data.data);;
        });
    },[]);

    useEffect(() => {
        axiosClient.get('seo/faqs').then(({ data }) => {
          setFaqs(data.data);
        });
    
      }, []);
 

  return (
    <Helmet title={faqs && faqs.seo_title}>
        <meta name="description" content={faqs && faqs.seo_meta_desc} />
        <meta name="keywords" content={faqs && faqs.seo_meta_name} />
        <Container>
            <Row className='py-md-5 d-flex  justify-content-center'>
        <div className="faqcontainer">
      <h1 className="faqtitle">FAQ’s for AV Production & Rental Services by DJRental-Florida</h1>
      <div className="faq-container">
        {Array.isArray(faq) && faq.map((datalist, index) => (
          <div className="faq" key={index}>
            <div className="ques">Q:  {datalist.title}</div>
            <div className="ans">A: {datalist.details}</div>
          </div>
        ))}
      </div>
    </div>
              
            </Row>
        </Container>
    </Helmet>
  )
}

export default Faq;