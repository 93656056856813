import React, { useState } from "react";
import './CashOnDelivery.css';
import stripeimg from '../../assets/images/img/stripe.png';
import { useSelector, useDispatch } from "react-redux";
import axiosClient from "../../axios-client";
import { cartActions } from "../../redux/slices/cartSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IoReceiptOutline } from "react-icons/io5";
import Spinner from "../../components/Spninnerloader/Spinner";
import { IoCloseSharp } from "react-icons/io5";


const CashOnDelivery = (props) => {

    const {cartitem} = props;
    // console.log(props, "props////////")
    const totalQty = useSelector((state) => state.cart.totalQuantity);
    const cartItems = useSelector((state) => state.cart.cartItems);
    const subtotal = useSelector((state) => state.cart.totalAmount);
    const islogin = useSelector((state) => state.user.isLogin);
    const totalAmountData = useSelector((state) => state.cart.totalAmount);
    const validSubtotal = isNaN(subtotal) ? 0 : parseFloat(subtotal);
    const shippingCostget = useSelector((state) => state.setting.shippingCost);
    const validShippingCost = isNaN(shippingCostget) ? 0 : parseFloat(shippingCostget);
    
    const shippingCostdata = validSubtotal > 0 ? validShippingCost : 0;
    const totalAmount = validSubtotal + shippingCostdata;
    const [updatedTotalAmount, setUpdatedTotalAmount] = useState(totalAmount);
    const dispatch = useDispatch();

    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [loading, setLogin] = useState(false);
    const navigate = useNavigate();
    const gestuser = localStorage.getItem("GUEST_INFO");
    const [getsUserInfo, setGestUserInfo] = useState(JSON.parse(gestuser));
    const cashonDelivery = () => {
        let type = {
            type: 'cashonDelivery',
        }
        props.payment(type);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!islogin) {
            toast.error("you are not login")
            navigate('/login');
            return;
        }

        // if (totalAmount <= 0) {
        //     toast.error('Add Product In Cart', {
        //         theme: "colored",
        //     });
        //     return;
        // }

        const data = {
            payment_type: 'cashOnDelivery',
            shipping_info: props.shippingInfo,
            payment_info: 'Cash',
            order_item: cartItems,
            total_payable: totalAmount,
            shippingCost: shippingCostdata,
            from:getsUserInfo.delivery,
            to:getsUserInfo.pickup
        };

        try {
            setLogin(true)
            const response = await axiosClient.post('cashOnDelivery/payment', data);
            const responseData = response.data;

            if (responseData.status === true) {
                toast.success(responseData.message, {
                    theme: "colored",
                });
                dispatch(cartActions.removeCartAllItem());
                localStorage.removeItem("GUEST_INFO");
                setPaymentStatus('success');

                setUpdatedTotalAmount(totalAmount); 

                setTimeout(() => {
                    setShowPaymentModal(false); 
                    navigate('/user/deshboard/3');
                    window.location.reload();
                }, 4000);
            } else {
                toast.error(responseData.message, {
                    theme: "colored",
                });
                setPaymentStatus('failure');
            }
            setShowPaymentModal(true);
            setLogin(false);
        } catch (error) {
            toast.error('An error occurred. Please try again.', {
                theme: "colored",
            });
            setPaymentStatus('failure');
            setShowPaymentModal(true);
        }
       
    };

    const closePaymentModal = () => {
        setShowPaymentModal(false); 
        setPaymentStatus(''); 
    };


    const getTotalValues = (items) => {
        if (!Array.isArray(items)) {
            return {
                Price: 0,
                totalInstallationCost: 0,
                totalDeliveryCost: 0,
                totalPrice: 0,
            };
        }
        return items.reduce(
            (totals, item) => {
                // Convert price to number
                const price = parseFloat(item.price.replace('.', '')) || 0;
    
                totals.Price += price;
                totals.totalInstallationCost += item.installation_cost;
                totals.totalDeliveryCost += item.delivery_cost;
                totals.totalPrice += item.totalPrice;
    
                return totals;
            },
            {
                Price: 0,
                totalInstallationCost: 0,
                totalDeliveryCost: 0,
                totalPrice: 0,
            }
        );
    };

    const totals = getTotalValues(cartitem);
  const getallprice = (totals.totalPrice) + ( totals.totalInstallationCost)+(totals.totalDeliveryCost)

    return (
        <div>
            <h6>Cash on Delivery</h6>
            <br />
            <h6>Payable Amount: {getallprice}</h6>

            <div>
            <button className="buy__btn w-100 mt-4" onClick={handleSubmit} disabled={loading}>
            <span>{loading ? 'Order Confirm...' : 'Order Confirm'}</span>
            </button>
            </div>

            {showPaymentModal && (
                <div className="payment-modal">
                    <div className="modal-contenti" >
                        <div className="modal-header">
                            
                        </div>
                        
                        <div className="modal-body">
                            
                                {paymentStatus === 'success' ? (
                                    <>
                                    <div className="payment-icon">
                                        <div className="pay-success-icon">
                                            
                                            <IoReceiptOutline style={{width: "60px" , height: "60px", }} />
                                        </div>
                                        </div>   
                                        <div className="pay-amount">${getallprice}</div>
                                        <h3 style={{color: "#3fb289"}}>Payment Successful!</h3>
                                        <h5>Thanks For Your Order.</h5>
                                    </>
                                ) : (
                                    <>
                                    <div style={{cursor:"pointer", fontSize:30,width:"100%",display:"flex" ,justifyContent:"end"}} onClick={closePaymentModal}><IoCloseSharp /></div>
                                    <div className="payment-icon">
                                        <div className="pay-failed-icon">
                                        <IoReceiptOutline style={{width: "60px" , height: "60px", }} />
                                        </div>
                                        
                                    </div>
                                        <div className="pay-amount">${getallprice}</div>
                                        <div className="pay-failed"><strong style={{color: "#ff476b"}}>Payment failed!</strong> Please try again.</div>
                                        
                                    </>
                                )}
                            
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CashOnDelivery;
