import React, { useEffect, useState ,useContext} from 'react';
import './AllproductCategory.css';
import axiosClient from '../axios-client';
import { Config } from '../Config';
import { Card, CardContent, CardActions, Typography, Button, Grid, Skeleton } from '@mui/material';
import { FaEye } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { cartActions } from '../redux/slices/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrencydata } from '../redux/slices/settingSlice';
import { BsBagPlus } from "react-icons/bs";
import { FiEye } from "react-icons/fi";
import { MdOutlineShoppingBag } from "react-icons/md";
import { PlanModalContext } from '../components/Layout/Layout'; 
import { moment } from 'moment'; // If you are using Moment.js for date manipulation
import { NotificationManager } from 'react-notifications';




function AllproductCategory(props) {
  const { products,selectedSubcategories } = props;
  
  const productIds = products ? products.map(product => product.id) : [];  // console.log(productIds,"ashdgsj");
  const islogin = useSelector((state) => state.user.isLogin);
  const guestInfo = useSelector((state) => state.user.guestInfo);
  const [loading, setLoading] = useState(false);

  const h4 = {
    fontSize: "16px",
    fontWeight: "600"
  };

  const img = {
    objectFit: "cover",
    height: "auto",
    width: "100%"
  };
  const {show, setShow} = useContext(PlanModalContext)
  const dispatch = useDispatch("");
  const [id, setid] = useState(props.id);
  const url = window.location.href;
  const [offerId, setofferId] = useState(0);
  let [productDetals, setProductDetails] = useState([]);
  let [productDiscount, setProductDiscount] = useState(0);
  // let [relatedProducts, setRelatedProducts] = useState([]);
  let [productImg, setProductImages] = useState([]);
  let [productmainImg, setProductMainImages] = useState('');
  let [showImg, setshowImg] = useState('');
  let [color, setColor] = useState(false);
  let [size, setSize] = useState(false);

  let [choose_color, setchoose_color] = useState('');
  let [choose_size, setchoose_size] = useState('');
  const currencySymbol = useSelector(state => state.setting.currency);
  const basepath = useSelector((state) => state.setting.basepath);
  const navigate = useNavigate();
  const [qty, setQty] = useState(1);


  let totalDiscount = 0;
  var discountPrc = 0;

  useEffect(() => {
    setColor(false)
    setSize(false)
    dispatch(getCurrencydata());
    axiosClient
      .get(`product/details?id=${productIds}`)
      .then(({ data }) => {
        //console.log("data", data);
        data.color && setColor(data.color.split(","))
        data.size && setSize(data.size.split(","))
        setProductDetails(data);
        setProductMainImages(data.image_path);
        setshowImg(data.image_path)
        setProductImages(data.product_image);
        setProductDiscount(data.discount)
      });
  }, [url]);



  const qtyChange = (typ2) => {
    if (typ2 === "plus") {
      setQty(pre => pre + 1)
    }
    if (typ2 === "neg") {
      setQty(pre => pre <= 1 ? 1 : pre - 1)
    }
  };

  var discountPercentageCal = 0;
  if (offerId > 0) {
    if (productDetals.offer_type == 0) {
      totalDiscount = Math.round(productDetals.offer_amount);
      discountPrc = Math.round(totalDiscount);
    }
    if (productDetals.offer_type == 1) {
      let totaldis = (productDetals.current_sale_price * productDetals.offer_amount) / 100;
      totalDiscount = Math.round(totaldis);
      discountPrc = Math.round(productDetals.offer_amount);
    }
  }
  else {
    if (productDetals.discount > 0) {
      if (productDetals.discount_type == 0) {
        totalDiscount = Math.round(productDetals.discount);
        discountPrc = Math.round(totalDiscount);
      }
      if (productDetals.discount_type == 1) {
        let totaldis = (productDetals.current_sale_price * productDetals.discount) / 100;
        totalDiscount = Math.round(totaldis);
        discountPrc = Math.round(totalDiscount);
      }
    }
  }
  const addToCart = (item, type) => {
    dispatch(
      cartActions.addItem({
        id: item.id,
        productName: item.name,
        price: item.current_sale_price - discountPrc,
        imgUrl: item.image_path,
        offerId: offerId,
        color: color,
        size: size,
        qty: qty,
        delivery_cost : item.delivery_cost,
        installation_cost : item.installation_cost,

      })
    );
   
    type == 'cartadd' && NotificationManager.success("product added successfully");
  };

  const p = {
    fontSize: "13px",
    fontWeight: "400",
    color:"#000"
  }

  const body1 = {
    fontSize: "18px",
    fontWeight: "600",
    color: "#092143"
  }



  const [getIdform,setGetIdform]=useState();


  const getfomrData = async() =>{
    try {
      await axiosClient.get('eventbuilder/get/id').then(resopnse=>{
        setGetIdform(resopnse.data.data)
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    getfomrData();
  },[]);


  const handleAddToCart = () => {
    const updatedAt = moment(getIdform.updated_at);
    const fiveMinutesAgo = moment().subtract(5, 'minutes');
    
    if (updatedAt.isAfter(fiveMinutesAgo)) {
      addToCart(products, 'cartadd');
    } else {
      setShow(true);
    }
  };


  const truncateName = (name, maxLength = 13) => {
    if (name.length <= maxLength) {
      return name;
    }
    return name.slice(0, maxLength) + "...";
  };


  const cateName = (name, maxLength = 90) => {
    if (!name) {
      return ''; 
    }
  
    if (name.length <= maxLength) {
      return name;
    }
  
    return name.slice(0, maxLength) + "...";
  };

  return (
    <>
      <div id="new_con" className="container d-flex align-items-center justify-content-start p-0" style={{ gap: "10px" }}>
      {Array.isArray(products) && products.length > 0 ? (
  <div className="row">
    {products.filter(product => selectedSubcategories.includes(product.subcategory_id) || selectedSubcategories.length === 0).map((product, index) => (
      <>
        <div key={product.id} className="col-md-3 col-6">
        <Card id="newcard" key={product.id} className='image-containers mt-4 mb-3'>
              <img loading='lazy' style={img} src={Config.baseUrl + "/" + product.image_path} alt={product.name} className="hover-img" />
              <CardActions style={{ background: "#092143", display: "flex", alignItems: "center", justifyContent: "space-around" }}>

              
                
                {guestInfo !== null ?(
                  <Button
                  onClick={() => addToCart(product, 'cartadd')}
                  id="ho-button"
                >
                  <MdOutlineShoppingBag />
                  Add To Cart
                </Button>
                ):(
                  <Button
                  onClick={()=>{setShow(true)}}
                  id="ho-button"
                >
                  <MdOutlineShoppingBag />
                  Add To Cart
                </Button>
                )}
                <Link to={`/shop/${product.id}`}>
                  <Button id="ho-button1">
                    <FiEye />
                    View
                  </Button>
                </Link>
              </CardActions>
              <CardContent>
                <Typography style={h4} variant="h5" component="h4">
                  {truncateName(product.name)}
                </Typography>
                <Typography style={p}  variant="h5" component="p" className="product-description">
                {product.description ? cateName(product.description) : ''}
                </Typography>
                <Typography style={body1} variant="body1">{`$${product.current_sale_price}`}</Typography>
              </CardContent>
            </Card>
        </div>
      </>
    ))}
  </div>
) : (
  <h6 className="mt-5">There is no product in this category</h6>
)}
      </div>
    </>
  );
}

export default AllproductCategory;
