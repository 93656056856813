import React from 'react'
import './footer.css';
import logo from '../../../src/assets/images/eco-logo.png';
import { Link } from 'react-router-dom';
import { BsLinkedin, BsYoutube, BsFacebook, BsInstagram, BsGithub } from "react-icons/bs";
import { useEffect } from 'react';
import axiosClient from '../../axios-client';
import { useState } from 'react';
import newsletter from "../../assets/images/newsletter.png"
import app1 from "../../assets/images/e-cab-logo-blue.png"
import app2 from "../../assets/images/basis-logo.jpg"
import app3 from "../../assets/images/BKash-Logo.wine.png"
import app4 from "../../assets/images/dutch-bangla-rocket-logo.png"
import { useNavigate } from 'react-router-dom';



const Footer = () => {
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [facegook, setFacebook] = useState('')
    const [aboutus, setaboutus] = useState('')
    const [privacy, setPrivacy] = useState('')
    const [refund, setRefund] = useState('')
    const [termsCondition, setTermsConditions] = useState('')
    const [shipping, setShipping] = useState('')
    const [signup, setSignup] = useState('');
    const navigate =  useNavigate()


    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // This enables smooth scrolling
        });
    }
    const joinNewlatter = ()=>{
        setSignup('');
        localStorage.setItem("newlatterEmail",signup);
        navigate('/signup');
        scrollToTop();

    }
    const faq = ()=>{
        navigate('/faq');
        scrollToTop();
    }
   
    useEffect(() => {
        axiosClient
            .get("product/company/info")
            .then(({ data }) => {
                setAddress(data.company_address);
                setPhone(data.phone);
                setEmail(data.email);
                setFacebook(data.facebook_link);
                setaboutus(data.about_us);
                setPrivacy(data.privacy_policy);
                setRefund(data.refund_policy);
                setTermsConditions(data.terms_condition);
                setShipping(data.shipping_policy);

                // console.log(data)
                // setProductList(data);
            });
    }, [])

    //   const year = new Date().getFullYear()

    

    return (
        <>
            {/* <div style={{width:"100%",overflow:"hidden"}}> */}
            {/* <footer className='py-5'>
            <div className="container">
                <div className="row align-items-center gx-5">
                    <div className="col-md-5">
                        <div className="footer-top-data">
                            <img src={newsletter} alt="" />
                            <h2> Sign Up for News Letter </h2>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="subscribe-group">
                            <input 
                                type="text" 
                                className="" 
                                placeholder="Your Email Address." 
                                aria-label="Your Email Address." 
                                aria-describedby="basic-addon2"
                            />
                            <span className="subscribe-text p-2" id="basic-addon2"> 
                                Subscribe
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </footer> */}

            {/* </div> */}

            {/* <footer className='py-4'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <h4 className='text-white mb-4'>Contact Us </h4>
                        <div className='office__info pe-5'>
                            <address>
                             {address}
                            </address>
                            <a className=' text-white mt-3 mb-1' href='tel:+880 1313 450785'> Contact: {phone} </a>
                            <br />
                            <a className=' text-white mt-2 mb-0' href='mailto:hello@reinforcelab.com'> Email: {email} </a>
                            <div className='social_icons d-flex align-items-center gap-15 mt-4'>
                                <Link to=""><BsLinkedin className='fs-5'/></Link>
                                <a href={facegook} target="_blank" ><BsYoutube className='fs-5'/></a>
                                <a href={facegook} target="_blank" ><BsFacebook className='fs-5'/></a>
                                
                                <Link to=""><BsInstagram className='fs-5'/></Link>
                                <Link to=""><BsGithub className='fs-5'/></Link>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <h4 className='text-white mb-4'> Informetion </h4>
                        <div className='footer-links d-flex flex-column'>

                         
                            <Link className=' text-white py-2 mb-1' to={`about/privacy/${privacy}`}> Privacy Policy </Link>
                            <Link className=' text-white py-2 mb-1'  to={`about/shipping/${shipping}`}>  Shipping Policy  </Link>
                            <Link className=' text-white py-2 mb-1'  to={`about/refund/${refund}`}>Refund Policy </Link>
                            <Link className=' text-white py-2 mb-1'  to={`about/terms/condition/${termsCondition}`}> Terms & Conditions </Link>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <h4 className='text-white mb-4'> Account </h4>
                        <div className='footer-links d-flex flex-column'>
                            <Link className=' text-white py-2 mb-1' to='aboutus/'> About Us  </Link>
                            <Link className=' text-white py-2 mb-1' to="faq/"> Faq </Link>
                            <Link className=' text-white py-2 mb-1' to="contact/us/ll">  Contact  </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <h4 className='text-white mb-4'> Our Partners </h4>
                        <p className='text-white mb-3'> Download Our App And get Extra 15% Discount on Your first Order...</p>
                        <div className='row flex-wrap gy-3'>
                           <div className='col-lg-4 col-md-4'>
                                <Link className='footer-app-links' to=""> <img src={app1} alt="" />  </Link>
                           </div>
                           <div className='col-lg-8 col-md-8'>
                                <Link className='footer-app-links' to=""> <img src={app2} alt="" />  </Link>
                           </div>
                       
                            <div className='col-lg-4 col-md-4'>
                                <Link className='footer-app-links' to=""> <img src={app3} alt="" />  </Link>
                            </div>
                            <div className='col-lg-8 col-md-8'>
                                <Link className='footer-app-links' to=""> <img src={app4} alt="" /> </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer> */}
            {/* <footer className='py-3'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                            <p className='footer__copyright mb-0 text-white align-items-center'>
                                &copy; {new Date().getFullYear()} Powerd by <Link to="https://reinforcelab.com/"> ReinforceLab Ltd.</Link> All rights reserved </p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                    <div className='footer-payment'>
                            <Link to=""> <img src="header-img/cc-badges-ppmcvdam.png" alt="" />  </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer> */}

            <div className="footer" >
                <div className='_main_footer'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-sm-4">
                                <div>
                                    <div>
                                        <div className='component_card' style={{ borderRight: "1px solid #092143" }}>
                                            <div>
                                                <div>
                                                    <div class="info-svg-wrapper">
                                                        <img src="/svgviewer-png-output.png" title="retail-free-shipping" width="55" height="40" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="info-box-content">
                                                <h4 class="info-box-title title box-title-style-default wd-fontsize-s">Exclusive Offer:-</h4>
                                                <div class="info-box-inner set-cont-mb-s reset-last-child">
                                                    <p className='wp-fontsize-p'>Unlock Your Exclusive Offer Today.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-4">
                                <div>
                                    <div>
                                        <div className='component_card' style={{ borderRight: "1px solid #092143" }}>
                                            <div>
                                                <div>
                                                    <div class="info-svg-wrapper">
                                                        <img src="https://woodmart.xtemos.com/wp-content/uploads/2018/08/retail-247.svg" title="retail-free-shipping" width="55" height="40" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="info-box-content">
                                                <h4 class="info-box-title title box-title-style-default wd-fontsize-s"> For 24*7 Support </h4>
                                                <div class="info-box-inner set-cont-mb-s reset-last-child">
                                                    <p className='wp-fontsize-p'>We’re here for you 24/7.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-4">
                                <div>
                                    <div>
                                        <div className='component_card' style={{ borderRight: "1px solid #092143" }}>
                                            <div>
                                                <div>
                                                    <div class="info-svg-wrapper">
                                                        <img src="https://woodmart.xtemos.com/wp-content/uploads/2018/08/retail-payment.svg" title="retail-free-shipping" width="55" height="40" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="info-box-content">
                                                <h4 class="info-box-title title box-title-style-default wd-fontsize-s">Online Payment: </h4>
                                                <div class="info-box-inner set-cont-mb-s reset-last-child">
                                                    <p className='wp-fontsize-p'>Secure Online Payment.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-4" >
                                <div>
                                    <div>
                                        <div className='component_card'>
                                            <div>
                                                <div>
                                                    <div class="info-svg-wrapper">
                                                        <img src="/fast-van.png" title="retail-free-shipping" width="55" height="40" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="info-box-content">
                                                <h4 class="info-box-title title box-title-style-default wd-fontsize-s"> Fast delivery: </h4>
                                                <div class="info-box-inner set-cont-mb-s reset-last-child">
                                                    <p className='wp-fontsize-p'>Enjoy Fast Delivery.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='_main_footer2'>
                    <div className="container">
                        <div className='row'>
                            <div className='col-lg-3 col-sm-6' sm={6}>
                                <div class="wpb_wrapper">
                                    <ul class="wd-sub-menu mega-menu-list wd-wpb">
                                        <li class="">
                                            <a>
                                                <span class="nav-link-text">
                                                    COMPANY LINKS
                                                </span>
                                            </a>
                                            <ul class="sub-sub-menu">
                                                <li class="">
                                                    <Link to="/aboutus" title="">
                                                        About Us
                                                    </Link>
                                                </li>

                                                {/* <li class="">
                                                    <a href="/comingsoon" title="">
                                                        dJ Peoples Group
                                                    </a>
                                                </li> */}


                                                <li class="">
                                                    <Link to="/all/product" title="">
                                                        DJ Equipment Rentals
                                                    </Link>
                                                </li>


                                                <li class="">
                                                    <a href="/comingsoon" title="">
                                                        Orlando
                                                    </a>
                                                </li>


                                                {/* <li class="">
                                                    <a href="#/demo/retail/" title="">
                                                        DJ Peoples
                                                    </a>
                                                </li> */}


                                                <li class="">
                                                    <a href='' onClick={faq} title="">
                                                        FAQ
                                                    </a>
                                                </li>

                                            </ul>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className='col-lg-3 col-sm-6' sm={6}>
                                <div class="wpb_wrapper">
                                    <ul class="wd-sub-menu mega-menu-list wd-wpb">
                                        <li class="">
                                            <a>
                                                <span class="nav-link-text">
                                                    USEFUL LINKS
                                                </span>
                                            </a>
                                            <ul class="sub-sub-menu">
                                                <li class="">
                                                    <a href="/comingsoon" title="">
                                                        Privacy Policy
                                                    </a>
                                                </li>

                                                <li class="">
                                                    <a href="/comingsoon" title="">
                                                        Returns
                                                    </a>
                                                </li>


                                                <li class="">
                                                    <a href="/comingsoon" title="">
                                                        Terms & Conditions
                                                    </a>
                                                </li>


                                                {/* <li class="">
                                                    <Link to="contact/us/:contactUs" title="">
                                                        Contact Us
                                                    </Link>
                                                </li>


                                                <li class="">
                                                    <a href="/comingsoon" title="">
                                                        Latest News
                                                    </a>
                                                </li>


                                                <li class="">
                                                    <a href="/comingsoon" title="">
                                                        Our Sitemap
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className='col-lg-3 col-sm-6' sm={6}>
                                <div class="wpb_wrapper">
                                    <ul class="wd-sub-menu mega-menu-list wd-wpb">
                                        <li class="">
                                            <a>
                                                <span class="nav-link-text">
                                                    CUSTOMER SERVICE
                                                </span>
                                            </a>
                                            <ul class="sub-sub-menu">
                                                <li class="">
                                                    <a href="/user/deshboard/1" title="">
                                                        My Account
                                                    </a>
                                                </li>

                                                <li class="">
                                                    <a href="/contact/us/:contactUs" title="">
                                                        Contact Us
                                                    </a>
                                                </li>


                                                <li class="">
                                                    <a href="/rental-agreement" title="">
                                                        rental Agreement
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                </div>
                            </div>

                            <div className='col-lg-3 col-sm-12'>
                                <div class="wpb_wrapper">
                                    <ul class="wd-sub-menu mega-menu-list wd-wpb">
                                        <li class="">
                                            <a>
                                                <span class="nav-link-text">
                                                    AVAILABLE ON:
                                                </span>
                                            </a>
                                            <ul class="sub-sub-menu">
                                                <li class="">
                                                    <a href="" title="">
                                                        Miami (305) 306-9600
                                                    </a>
                                                </li>

                                                <li class="">
                                                    <a href="" title="">
                                                        Ft Lauderdale (954) 361-2876
                                                    </a>
                                                </li>


                                                <li class="">
                                                    <a href="" title="">
                                                        email : marc@marcvane.com
                                                    </a>
                                                </li>


                                                <ul class="wd-sub-menu mega-menu-list wd-wpb" style={{ padding: "0px" }}>
                                                    <li class="">
                                                            <span class="nav-link-text">
                                                                Join our newsletter!
                                                            </span>
                                                    </li>
                                                    <li class="">
                                                        <div class="mc4wp-form-fields"><p class="mailchimp-input-icon">

                                                            <input type="email" className='footer_email' name="EMAIL" onChange={(e)=>setSignup(e.target.value)} placeholder="Your email address" required="" />
                                                        </p>

                                                            
                                                        </div>
                                                        <p>
                                                                <input type="submit" style={{background:"#030a94", color:"#fff"}} onClick={joinNewlatter} value="Sign up" />
                                                            </p>
                                                    </li>
                                                </ul>

                                            </ul>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='_main_footer3'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4 col-sm-6 pr-2'>
                                <div className='main_sec'>
                                    <h5 className='mb-3'>Payment System:</h5>
                                    <div className='logo_group'>
                                        <img src='https://woodmart.b-cdn.net/wp-content/uploads/2018/08/payment.png.webp' alt='card' />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-sm-6 pr-2'>
                                <div className='main_sec'>
                                    <h5 className="mb-3">Shipping System:</h5>
                                    <div className='logo_group'>
                                        <img src='https://woodmart.b-cdn.net/wp-content/uploads/2018/08/shipping.png.webp' alt='card' />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-sm-6'>
                                <div className='main_sec'>
                                    <h5 className='mb-3'>Our Social Links:</h5>
                                    <div className='logo_group wd-social-icons'>
                                        <a className='a_icons' href='https://www.facebook.com/theDJPEOPLES/videos'>
                                            <i className="bi bi-facebook" style={{ fontSize: "1.5rem" }}></i>
                                        </a>
                                        <a className='a_icons' href='https://x.com/thedjpeoples?mx=2'>
                                            <i className="bi bi-twitter" style={{ fontSize: "1.5rem" }}></i>
                                        </a>
                                        <a className='a_icons' href='https://in.pinterest.com/djpeoples/'>
                                            <i className="bi bi-pinterest" style={{ fontSize: "1.5rem" }}></i>
                                        </a>
                                        <a className='a_icons' href='https://www.linkedin.com/'>
                                            <i className="bi bi-linkedin" style={{ fontSize: "1.5rem" }}></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='_main_footer4 pt-4 text-black text-center'>
                    <small>
                        Copyright ©2024 . All Rights Reserved.
                    </small>
                </div>
            </div>
        </>

    )
}



export default Footer;