
import React from 'react';
import './agreement.css'; 


const RentalAgreement = () => {
  return (
    <div className="agreement">
      <h1 className="agtitle">Rental Agreement</h1>
      <h2>TERMS AND CONDITIONS OF RENTAL AGREEMENT</h2>
      <div>
        The following are terms and conditions that apply to the rental of equipment and services provided by DJRental LLC. By accepting equipment and services furnished under this agreement, the customer whose name appears on the agreement accepts and agrees to these terms and conditions.
      </div>

      <h2>EQUIPMENT USAGE</h2>
      <div>
        Customer shall not make any alterations, additions, modifications, or improvements to the equipment and/or environment and shall use it only for the purpose and in the manner for which it was intended by the manufacturer. The customer acknowledges that the equipment and services received are in a safe and working condition. 
      </div>
      <div>
        Customer may not permit the equipment to be used by another party or in a different environment without expressed written consent. DJRental LLC reserves the right to deny release of equipment based on weather forecasts for outdoor venues and/or other threats.
      </div>

      <h2>LIMITED LIABILITY</h2>
      <div>
        DJRental LLC is limited to providing safe and functioning equipment and drape per industry standards. Customer agrees to protect, hold harmless, and indemnify DJRental LLC and its Officers for and with respect to any loss, cost, damage or expense (including, but not limited to, attorneys’ fees, court costs and costs of investigation) incurred by reason of the death of, or injury to, any person(s) or by reason of damage to any and all property (real, personal or mixed) belonging to any person(s), provided and only to the extent that such death, injury, or property damage shall have arisen out of or resulted from the negligence or intentional misconduct of the Customer.
      </div>

      <h2>EQUIPMENT DAMAGE AND LIABILITY</h2>
      <div>
        Customer is fully responsible and liable for any loss or damage for the duration of the rental, regardless if done by others. Loss and/or damage fees are in addition to rental charges. Damage and Loss charges can far exceed the cost of the rental. Valuation of Damage can take up to (5) business days. Customer agrees to promptly pay assessed damage and/or loss charges and in the event of non-payment the renter agrees to pay any collection costs incurred to collect the amount due including reasonable attorney and court fees. 
      </div>
      <ul>
        <li>Keep equipment away from liquids, tape, flame, wind, heat, and other hazardous materials and/or conditions.</li>
        <li>Do Not alter equipment installed professionally by DJRental LLC.</li>
        <li>Store and transport equipment by safe, standard industry practices.</li>
      </ul>

      <h2>CANCELLATION FEES</h2>
      <div>
        For Events within 10 days of Rental or Event, No Cancellations.
      </div>
      <div>Prior to 10 days – 50% Refund</div>
      <div>Prior to 15 Days – 100% Refund</div>

      <h2>LEGAL</h2>
      <div>
        If any legal action is necessary to enforce the terms of this agreement, the Lessee agrees to pay all costs and reasonable attorney fees, including costs and fees on appeal, in addition to any other relief to which DJRental may be entitled.
      </div>

      <div className="warning">
        ALL RENTER MUST SHOW DRIVER’S LICENSE AND CREDIT CARD TO BE ABLE TO PICK UP THEIR EQUIPMENT. WE PROCESS A SECURITY AUTHORIZATION ON ALL RENTALS.
      </div>
    </div>
  );
};

export default RentalAgreement;
