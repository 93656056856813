import React, { useState, useEffect } from 'react';
import "./full.css";
import Pro1 from "../assets/images/pro1.png";
import Pro2 from "../assets/images/Mechanic.png";
import Imp from "../assets/images/imp.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import { cartActions } from '../redux/slices/cartSlice';
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import Spinner from '../components/Spninnerloader/Spinner';
import axiosClient from '../axios-client';
import { Link } from 'react-router-dom';

function FullInfo({ cartItems, basepath,cartitem,wait,getcartData }) {

    const [installationDate, setInstallationDate] = useState(new Date());
    const [installationTimeFrom, setInstallationTimeFrom] = useState(new Date());
    const [installationTimeTo, setInstallationTimeTo] = useState(new Date());

    const [breakdownDate, setBreakdownDate] = useState(new Date());
    const [breakdownTimeFrom, setBreakdownTimeFrom] = useState(new Date());
    const [breakdownTimeTo, setBreakdownTimeTo] = useState(new Date());

    const [isCardVisible, setIsCardVisible] = useState(false);
    const [view, setView] = useState(false);



    const handleAddTechnicianClick = () => {
        setIsCardVisible(true);
    };
    const handleViewpackage =() =>{
        setView(true);
    }
    const handleclosepackage =() =>{
        setView(false);
    }

  
    const handlePriceClick = () => {
        setIsCardVisible(false);
    };
    

    const p = {
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: "12px",
        fontWeight: "400",
        color: "#555"
    }

    const sp = {
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: "12px",
        fontWeight: "600",
        color: "#555"
    }

    const dispatch = useDispatch();
    
    const [cartItemss, setCartItemss] = useState(cartitem);


    useEffect(() => {
        if (cartitem) {
          setCartItemss(cartitem);
        }
      }, [cartitem]);
    //  const [cartItemss, setCartItemss] = useState(JSON.parse(localStorage.getItem("cart_item")));
   
      const [itemId, setItemId] = useState(null);

    const qtyChange = (id, typ2) => {

        let newQuantity = 0;
        setCartItemss(prevItems => {
            const updatedItems = prevItems.map(item => {
                if (item.id === id) {
                    newQuantity = parseInt(item.quantity ?? 0);  // Track the current quantity
                    if (typ2 === "plus") {
                        newQuantity = parseInt( item.quantity ?? 0) + 1;
                        
                    }
                    if (typ2 === "neg" && item.quantity > 1) {
                        newQuantity = parseInt(item.quantity ?? 1) - 1;
                        
                    }
                    return { ...item, quantity: newQuantity };
                }
                return item;
            });
    
            return updatedItems;
        });

        setItemId(id);

    };

    const skuhsn =(item)=>{
            const params = {unit: item.quantity,};
                axiosClient.get(`user/update/cart/${item.id}`, {params})
                    .then(() => {
                        setItemId(null);
                        getcartData();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
    }
    useEffect(() => {
        if(itemId !== null) {
            const item = cartItemss.find((data) => data.id === itemId)
            skuhsn(item);
        }
    }, [cartItemss, itemId]);



    // console.log("newQuantity",newQuantity);
    const deleteProduct = async(item) => {

     await axiosClient.get(`user/delete/cart/${item.id}`);
     getcartData();
        // dispatch(cartActions.deleteItem(item.id));
    };

    useEffect(() => {
        Array.isArray(cartitem) && cartitem.forEach(item => {
            if (item.quantity === 0) {
                dispatch(cartActions.deleteItem(item.id));
            }
        });
    }, [cartitem, dispatch]);

    //console.log("cartItemss",cartItemss)
    

    return (
        <>
            <div className="container">
                <div className="full_info_main mb-4 ">
                    <div className="full_h1" style={{display:"flex",justifyContent:"space-between"}}>
                        <h1 className="full_head">Production Times and Labour</h1>
                        {!view ? <div className='add_technician'>
                            <button onClick={handleViewpackage}>View package</button>
                        </div>: <RxCross2 style={{ fontSize: 20, cursor: "pointer", marginLeft:20}} onClick={handleclosepackage} />}
                    </div>
                </div>
               {wait ? <Spinner/>: 
               <div className="full_info1">
                {view && <div className='package_list'>
                {Array.isArray(cartItemss) && cartItemss.map((img)=>{
                    return(
                        <>
                        <img
                        style={{ height: "80%", width: "120px", paddingTop: "10px", paddingBottom: "10px", borderRadius: "10px" }}
                        src={`${basepath}/${img.imgUrl}`}
                        alt="DAS Event Array"
                        className="img"
                        />
                        </>
                    )
                })}
                <Link style={{display:"flex", fontSize:15, justifyContent:"right", color:"rgb(0, 123, 255)"}} to="/all/product">Add more</Link>
                </div>
                
                }
                
                    <div className="full_box">
                        <div className="">
                        {Array.isArray(cartItemss) && cartItemss.map((item, index) => {
                            console.log("item",item);
                return (
                    <div className="full_card p-3" key={item.id}>
                        <div className="row align-items-center">
                            <div id="image_sec" className="col-md-1 text-center">
                                <img
                                    style={{ height: "80%", width: "40px", paddingTop: "10px", paddingBottom: "10px", borderRadius: "10px" }}
                                    src={`${basepath}/${item.imgUrl}`}
                                    alt="DAS Event Array"
                                    className="img"
                                />
                            </div>
                            <div className="col-md-6">
                                <Link to={`/shop/${item.product_id}`}><h5 style={{ fontSize: "1.15rem" }}>{item.productName}</h5></Link>
                            </div>
                            <div className="col-md-2 d-flex align-items-center">
                                <button
                                    className="qt_btn bsewd"
                                    onClick={() => qtyChange(item.id, "neg")}
                                >
                                    <FaMinus style={{ fontSize: "12px" }} />
                                </button>
                                <div>
                                    <input type="number" value={item.quantity} readOnly className="qtyinput" />
                                </div>
                                <button
                                    className="qt_btn bsewd"
                                    onClick={() => qtyChange(item.id, "plus")}
                                >
                                    <FaPlus style={{ fontSize: "12px" }} />
                                </button>
                            </div>
                            <div className="col-md-2 text-md-right text-center mt-3 mt-md-0">
                                <h5 style={{ fontSize: "1.15rem" }} className="text-primary">
                                    ${(item.price * item.quantity).toFixed(2)}
                                </h5>
                            </div>
                            <div className='col-md-1'>
                                <RxCross2 style={{ fontSize: 20, cursor: "pointer" }} onClick={() => deleteProduct(item)} />
                            </div>
                        </div>
                    </div>
                );
            })}
                        </div>
                        {/* <div className="text_accor text-end">
                            <p style={p}>View Products Included in Bundle <i class="bi bi-chevron-down"></i></p>
                        </div> */}
                    </div>
                </div>}
                {/* Additional content omitted for brevity */}
                <div className="new_full_tab">
                    <div className="full_card p-3">
                        <div className="row align-items-center">
                            <div id="image_sec" className="col-md-1 text-center">
                                <img
                                    src={Pro2}
                                    alt="DAS Event Array"
                                    className="img image_main_c"
                                />
                            </div>
                            <div className="col-md-8">
                                <h5 style={{ fontSize: "1.15rem" }} className="mb-2">Delivery Installation and Breakdown</h5>
                                <ul className="list-unstyled">
                                    <li>Labour fees includes delievery at undefined, installation and breakdown</li>
                                </ul>

                            </div>
                            <div className="col-md-3 text-md-right text-center mt-3 mt-md-0">
                                <h5 className="text-primary">$350.00</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new_full_tab1">
                    <div className="full_card p-3">
                        <div className="row align-items-center">
                            <div id="image_sec" className="col-md-1 text-center">
                                <img
                                    src={Pro2}
                                    alt="DAS Event Array"
                                    className="img image_main_c"
                                />
                            </div>
                            <div className="col-md-8">
                                <h5 style={{ fontSize: "1.15rem" }} className="mb-2">Breakout after hour fees</h5>
                                <ul className="list-unstyled">
                                    <li>Afterhour charge of between 8:00 to 8:00 pm</li>
                                </ul>

                            </div>
                            <div className="col-md-3 text-md-right text-center mt-3 mt-md-0">
                                <h5 className="text-primary">$150.00</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new_full_tab2">
                    <div className="full_card p-3">
                        <div className="row align-items-center">
                            <div id="image_sec" className="col-md-1 text-center">
                                <img
                                    src={Pro2}
                                    alt="DAS Event Array"
                                    className="img image_main_c"
                                />
                            </div>
                            <div className="col-md-8">
                                <h5 style={{ fontSize: "1.15rem" }} className="mb-2">Florida Sales Tax</h5>
                                <ul className="list-unstyled">
                                    <li>Florida sales tax 7% over rental price. if you have tax exemtion certificate , please get contact us at 0000000</li>
                                </ul>

                            </div>
                            <div className="col-md-3 text-md-right text-center mt-3 mt-md-0">
                                <h5 className="text-primary">$150.00</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info_ful_l">
                    <p style={p} className="p-4 pb-2"><span style={sp}>Important!</span> Please review carefully the installation and breakdown times before proceeding to checkout to avoid rescheduling fees. if you need to, you can Click here to change times </p>
                    <p style={p} className="p-4 pt-0">This Order might seem bit complex, but we got you covered with immediate expert guidence just a click away our contact options and save your order for any future revisions. </p>
                </div>
                {!isCardVisible && (
                <div className='add_technician'>
                    <button onClick={handleAddTechnicianClick}>Add Technician</button>
                </div>
            )}

            {isCardVisible && (
                <div className="new_full_tab1">
                    <div className="full_card p-3">
                        <div className="row align-items-center">
                            <div id="image_sec" className="col-md-1 text-center">
                                <img
                                    src={Pro2}
                                    alt="DAS Event Array"
                                    className="img image_main_c"
                                />
                            </div>
                            <div className="col-md-8">
                                <h5 style={{ fontSize: "1.15rem" }} className="mb-2">Breakout after hour fees</h5>
                                <ul className="list-unstyled">
                                    <li>Afterhour charge of between 8:00 to 8:00 pm</li>
                                </ul>
                            </div>
                            <div className="col-md-3 text-md-right text-center mt-3 mt-md-0 d-flex">
                                <h5 className="text-primary" >$150.00</h5>
                                <RxCross2 style={{ fontSize: 20, cursor: "pointer", marginLeft:20}} onClick={handlePriceClick} />
                            </div>
                          
                        </div>
                    </div>
                </div>
            )}
            </div>
        </>
    )
}

export default FullInfo;
