import React from "react";
import { Col, Container, Form, FormGroup, Row, Input } from "reactstrap";

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { AiOutlineCreditCard } from "react-icons/ai";
import stripeimg from '../../assets/images/img/stripe.png'
import chashon from '../../assets/images/img/cashon2.png'
import pp_fc_hl from '../../assets/images/pp_fc_hl.svg'
import { useState } from "react";
import CashOnDelivery from "../paymentMethod.jsx/CashOnDelivery";
import InternationalCard from "../paymentMethod.jsx/InternationalCard";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PayPal from "../paymentMethod.jsx/PayPal";
import sheduleCall from "../../assets/images/call-schedule-24.png"
import SheduleCall from "../paymentMethod.jsx/SheduleCall";
const OrderShipping = (props) => {

    const {cartitem} = props;

    const [payment, setPayment] = useState(0);
    const [shippingInfo, setShippingInfo] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();


    useEffect(() => {
        setShippingInfo(props.shippingInfo);

    }, [])
    const paymentMethod = (method) => {
        setPayment(method)
        setSelectedPaymentMethod(method);
    }

    const cashonDelivery = () => {
        alert('cashondelivery')
    }

    const internationlPayment = () => {
        alert('internationlPayment')
    }



    const confirmPayment = (data) => {
        data.type === 'cashonDelivery' && cashonDelivery()
        data.type === 'stripe' && internationlPayment()

    }

   
    return <div>
            <div>
                <div>
                    <h6 class="mb-1 pb-3 checkout__txt "> Payment Method </h6>
                    <div className="paycard_div">
                        <div className="paymentIcon payicontop">

                            <FormControlLabel value="female" checked={selectedPaymentMethod === 1} control={<Radio />} label="Cash on Delivery" onClick={() => paymentMethod(1)} />
                            <div className="imgBackground">
                                <img src={chashon} style={{ height: "21px", marginLeft: "2px", width: "55px" }} />
                            </div>
                        </div>
                        <div className="paymentIcon">
                            <FormControlLabel value="male" checked={selectedPaymentMethod === 2} control={<Radio />} label="International payment" onClick={() => paymentMethod(2)} />
                            <div className="imgBackground">
                                <img src={stripeimg} />
                            </div>
                        </div>
                        <div className="paymentIcon">
                            <FormControlLabel value="male" checked={selectedPaymentMethod === 4} control={<Radio />} label=" Schedule a call" onClick={() => paymentMethod(4)} />
                            <div className="imgBackground">
                                <img src={sheduleCall} style={{ height: "30px", marginLeft: "2px", width: "30px" }} />
                            </div>
                        </div>
                        

                        <div className="paymentIcon">
                        <FormControlLabel value="paypal" checked={selectedPaymentMethod === 3} control={<Radio />} label="PayPal" onClick={() => paymentMethod(3)} />
                        <div className="imgBackground">
                            <img src={pp_fc_hl} style={{ height: "21px", marginLeft: "2px", width: "55px" }} />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="payment__div">
                {
                    payment>0 && <div className="paypal_">
                        {
                            payment == 1 && <CashOnDelivery cartitem={cartitem} payment={confirmPayment} shippingInfo={props.shippingInfo} /> || payment == 2 && <InternationalCard payment={confirmPayment} shippingInfo={props.shippingInfo} /> || payment == 3 && <PayPal/> || payment == 4 && <SheduleCall cartitem={cartitem} payment={confirmPayment} shippingInfo={props.shippingInfo} />
                        }
                    </div>
                }
                </div>

                

            </div>
    </div>
}
export default OrderShipping;