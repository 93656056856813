import React, { useContext, useEffect, useState } from "react";
import Helmet from "../components/Helmet/Helmet";
import "../styles/shop.css";
import { Col, Container, Row } from "reactstrap";
import axiosClient from "../axios-client";
import { UncontrolledCollapse } from "reactstrap";
import "../styles/allproduct.css";

import { Link, useParams, useSearchParams } from "react-router-dom";
import { BiReset } from "react-icons/bi";
import { useQuery } from "react-query";
import AllproductCategory from "./AllproductCategory";
import { Config } from "../Config";
import {
  Card,
  CardActions,
  CardContent,
  Skeleton,
  Typography,
} from "@mui/material";
import Carousel from 'react-bootstrap/Carousel';
import searchslider from '../assets/images/ec-search.png'
import calenderSlider from '../assets/images/ec-calendar.png'
import creditSlider from '../assets/images/ec-credit-card.png'
import { PlanModalContext } from "../components/Layout/Layout";


const AllProductList = ({ categoryId }) => {
  const { category_id, subcategory_id, type, offer } = useParams();
  const categoryPrm = category_id == undefined ? 0 : category_id;
  const subCategoryPrm = category_id == undefined ? 0 : subcategory_id;
  const typeprm = type == undefined ? 0 : type;

  const [searchParams] = useSearchParams();
  const query_cat = Number(searchParams.get("cat")); // 'name

  const popularcategory = useQuery(
    "popularCategory",
    async () =>
      await axiosClient
        .get("product/popular/category")
        .then(({ data }) => data),
    {
      refetchOnMount: false,
      refetchInterval: 60000, // 1 minute interval
    }
  );

  const [popularCategories, setPopularCategories] = useState([]);

  const [productlist, setProductList] = useState([]);
  const [categorySubcategory, setcategorySubcategory] = useState();

  const [category, setcategory] = useState(categoryPrm);
  const [subcategory, setsubcategory] = useState(subCategoryPrm);
  const [typey, settype] = useState(typeprm);
  const [selectColor, setselectColor] = useState("0");
  const [selectSiz, setselectedSiz] = useState("0");
  const [category_Id, setCategory_Id] = useState(
    query_cat || popularCategories[0]?.id
  );

  const [subCateg, setSubCateg] = useState();
  const [subloading, setSubloading] = useState(true);
   const {show, setShow} = useContext(PlanModalContext)

  const [categoryProducts, setCategoryProducts] = useState();
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState(null);


  useEffect(() => {
    setLoading(true);
    axiosClient.get("product/category/product").then(({ data }) => {
      setcategorySubcategory(data);
    });

    axiosClient.get("product/category/product").then(({ data }) => {
      setCategoryProducts(data);
      setLoading(false);
    });

    axiosClient.get("all/category/subcategory").then(({ data }) => {
      setSubCateg(data);
    });

    axiosClient.get("product/popular/category").then(({ data }) => {
      if (data) {
        setSubloading(true);
        setPopularCategories(data);
        setCategory_Id(data[0]?.id);
        setSubloading(false);
      }
    });
  }, []);

  const initialActiveTab = popularCategories
    ? popularCategories&&popularCategories.findIndex((category) => category.id === query_cat)
    : -1;

  useEffect(() => {
    if (query_cat) {
      setCategory_Id(query_cat);
      const matchingIndex = popularCategories.findIndex(
        (category) => category.id === query_cat
      );
      if (matchingIndex !== -1) {
        setActiveTab(matchingIndex);
      }
    }
  }, [query_cat, popularCategories]);

  const matchingCategory = categoryProducts && Array.isArray(categoryProducts) && categoryProducts.find((product) => product.id === category_Id);

  const products = matchingCategory && matchingCategory.product;

  const subcategoryIdToMatch = Number(selectedSubcategories);
  const matchingProducts = products && products.filter(product => product.subcategory_id === subcategoryIdToMatch);
  // console.log("matchingProducts",matchingProducts.length);

  // if (matchingProducts && matchingProducts.length > 0) {
  //   console.log(`Number of products with subcategory_id ${subcategoryIdToMatch}: ${matchingProducts.length}`);
  // } else {
  //   console.log(`No products found with subcategory_id ${subcategoryIdToMatch }:${matchingProducts.length}`);
  // }

  function valuetext(value) {
    return `${value}°C`;
  }

  const minDistance = 10;

  const [value1, setValue1] = React.useState([0, 40000]);

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const categoryProduct = (category_id) => {
    setcategory(category_id);
    setsubcategory(0);
  };

  const reset = (categoryId) => {

    setCategory_Id(categoryId); // Set the clicked category ID
    setExpandedCategory(categoryId); // Expand the clicked category
  
    // Reset other state when clicking the button
    setSelectedSubcategories([]); // Reset selected subcategories
    setExpandedCategory(null);
    setExpandedCategory()
    setselectedSiz(0);
    setselectColor(0);
    let srcdatalist = {
      min: value1[0],
      max: value1[1],
      category_id: category,
      sub_category_id: subcategory,
      color: 0,
      size: 0,
      type: typey,
    };
    axiosClient
      .post("product/price/range/src", srcdatalist)
      .then(({ data }) => {
        setProductList(data);
      });
  };

  const subCategoryProduct = (subCategory_id) => {
    setsubcategory(subCategory_id);
  };

  const getrangewiseproduct = () => {
    let srcdatalist = {
      min: value1[0],
      max: value1[1],
      category_id: category,
      sub_category_id: subcategory,
      color: selectColor,
      size: selectSiz,
      type: typey,
    };
    axiosClient
      .post("product/price/range/src", srcdatalist)
      .then(({ data }) => {
        setProductList(data);
      });
  };

  const [activeTab, setActiveTab] = useState(
    initialActiveTab !== -1 ? initialActiveTab : 0
  );
  const [loading, setLoading] = useState(false);
  const handleTabClick = (categoryId, tabNumber,cateId) => {

    setExpandedCategory(expandedCategory === cateId ? null : cateId);
    setLoading(true);
    setActiveTab(tabNumber);
    setSelectedSubcategories([]);
    setTimeout(() => {
      setLoading(false);
      if (categoryId) {
        setCategory_Id(categoryId);
      }
    }, 2000);
  };
  const card = {
    width: "100%",
    // position:"relative"
  };

  const handleSubcategoryClick = (subId) => {
    setSelectedSubcategories([subId]); // Remove all previous selections and select the new one
  };

 //console.log("popularCategories",popularCategories);
 const [service, setService] = useState('');

 useEffect(() => {
  axiosClient.get('seo/services').then(({ data }) => {
    setService(data.data);
  });

}, [])

// console.log("popularCategories",popularCategories);


  return (
    <Helmet title={service && service.seo_title}>
      <meta name="description" content={service && service.seo_meta_desc} />
      <meta name="keywords" content={service && service.seo_meta_name} />

      <Container fluid>
        <Row>
          <Col lg="3" md="3" className="category-sidebar">
            <div className="main-products-wrapper">
              <div className="main-products">
                <section className="new__arrivals">
                  <Container>
                    <Row className="">
                      <Col lg="12" className="srcdiv">
                        <div>
                          <h4 className="sideitemtxt">
                            Category{" "}
                            <span onClick={reset} className="reseticon">
                              <BiReset />
                            </span>
                          </h4>
                        </div>
                        <ul className='list_item'>
                          { Array.isArray(subCateg) &&
                            subCateg.map((cate) => (
                              <li key={cate.id}>
                                <button
                                  className={`category_name ${
                                    cate.id === category_Id
                                      ? "active_category"
                                      : ""
                                  }`}
                                  id={`cate${cate.id}`}
                                  onClick={() => handleTabClick(cate.id)}
                                >
                                  {cate.name}
                                </button>
                                <UncontrolledCollapse
                                  toggler={`#cate${cate.id}`}
                                  className={
                                    cate.id === category_Id ? "show" : ""
                                  }
                                  isOpen={expandedCategory === cate.id}
                                >
                                  {cate.subcategory.map((sub) => (
                                    
                                    <li
                                      key={sub.id}
                                      className={`sub_category_name ${
                                        selectedSubcategories.includes(sub.id)
                                          ? "selected_subCategory"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleSubcategoryClick(sub.id)
                                      }
                                      style={{display: sub.deleted === 1? "none":""}}
                                    >
                                      -{" "}
                                      <span className="testbd" >{sub.name}</span>
                                    </li>
                                  ))}
                                </UncontrolledCollapse>
                              </li>
                            ))}
                        </ul>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </div>
            </div>
          </Col>

          <Col lg="9" md="9">
            {subloading ? (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 10 }}
                  width="140px"
                  height={140}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 10 }}
                  width="140px"
                  height={140}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 10 }}
                  width="140px"
                  height={140}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 10 }}
                  width="140px"
                  height={140}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 10 }}
                  width="140px"
                  height={140}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 10 }}
                  width="140px"
                  height={140}
                />
              </div>
            ) : (<>
                
                <Carousel style={{marginTop:25}}>
                { popularCategories &&
                  popularCategories.map((category, index) => (<Carousel.Item>
                    {/* <div className="carousel_image" style={{backgroundImage:`url(${Config.baseUrl}/${category.image})`}}></div> */}
                  <img className="image_carouser_" style={{cursor:"pointer", height:"70vh"}} src="https://djrentalapi.btruetech.com//storage/slide/1729233803_edwards-lifesciences-category-hero_2200x1042.webp" key={index}  onClick={() => handleTabClick(category.id, index)} alte="slider images" />
                  <Carousel.Caption id="captions_name">
                    <h3  className="text-center">{category.name}</h3>
                      <div className="text_caption">{category.note}</div>
                      <Link to=""  className="slider_link">Easy online booking + 24/7service</Link>
                      <div className="service_buttons">
                        <Link to=""><img style={{ width: 40 }} src={searchslider}></img><br></br><span style={{fontWeight:600,marginTop:10}}> Choose your gear</span> </Link>
                        <Link to="" ><img style={{width:40}} src={calenderSlider}></img><br></br><span style={{fontWeight:600,marginTop:10}}>Get a quote</span></Link>
                        <Link to="" ><img style={{width:40}} src={creditSlider}></img><br></br><span style={{fontWeight:600,marginTop:10}}> Pay online </span></Link>

                      </div>
                    </Carousel.Caption>
                    <div className='new_carousal_button'>
                        <button id="new_button_click_1" onClick={()=>setShow(true)}  >Start your rental</button>
                        
                        <button id="new_button_click_1" > <Link to="/contact/us/:contactUs" >Talk to a Pro</Link> </button>
                       
                   </div>
                    
                </Carousel.Item>
                ))}
              </Carousel>

              {/* <div className="tab-menu">
                {popularCategories &&
                  popularCategories.map((category, index) => (
                    <div
                      key={index}
                      className={`tab ${activeTab === index ? "active" : ""}`}
                      onClick={() => handleTabClick(category.id, index)}
                    >
                      <div className="image-container">
                        <img
                          loading="lazy"
                          src={Config.baseUrl + "/" + category.image}
                          alt={category.name}
                        />
                        <div className="overlay">{category.name}</div>
                      </div>
                    </div>
                  ))}
              </div> */}
            </>)}
            <div className="tab-content">
              {loading ? (
                <div className="row d-flex align-items-center justify-content-start ">
                  {[1, 2, 3, 4].map((index) => (
                    <div className="col-md-3 col-xs-6 col-lg-3" key={index}>
                      <Card style={card} className="image-containers mt-4 mb-3">
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={200}
                        />
                        <CardContent>
                          <Typography variant="h5" component="h4">
                            <Skeleton />
                          </Typography>
                          <Typography variant="body1">
                            <Skeleton width="60%" />
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Skeleton
                            variant="rectangular"
                            width="100px"
                            height="40px"
                          />
                          <Skeleton
                            variant="rectangular"
                            width="40px"
                            height="40px"
                            style={{ marginLeft: "auto" }}
                          />
                        </CardActions>
                      </Card>
                    </div>
                  ))}
                </div>
              ) : (
                category_Id && (
                  <AllproductCategory
                    products={products}
                    selectedSubcategories={selectedSubcategories}
                  />
                )
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Helmet>
  );
};

export default AllProductList;
