import Carousel from 'react-bootstrap/Carousel';
import "./main.css";
import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect, useContext } from 'react';
import CloseButton from 'react-bootstrap/CloseButton';
import TechSteps from './TechSteps';
import axiosClient from '../axios-client';
import { Config } from '../Config';
import PlanModal from '../components/PlanModal';
import { PlanModalContext } from '../components/Layout/Layout';
import CarouselSkeleton from './CarouselSkeleton';
import { Link } from 'react-router-dom';


const formatSubHeading = (subHeadingStr) => {
    // Define the patterns for different formatting styles
    const boldPattern = /\*\*(.*?)\*\*/g;
    const underlinePattern = /__(.+?)__/g;
    const bulletPattern = /^- (.+)/gm;
    const paragraphPattern = /\n(.+)/g;

    const ul = {
        listStyle: "disc"
    }

    // Replace the patterns with appropriate JSX elements
    let formattedSubHeading = subHeadingStr.replace(boldPattern, (match, content) => <b>{content}</b>);
    formattedSubHeading = formattedSubHeading.replace(underlinePattern, (match, content) => <u>{content}</u>);
    formattedSubHeading = formattedSubHeading.replace(bulletPattern, (match, content) => <ul style={ul}>{content}</ul>);
    formattedSubHeading = formattedSubHeading.replace(paragraphPattern, (match, content) => <p>{content}</p>);

    return formattedSubHeading;
};

function Main() {

    const { show, setShow } = useContext(PlanModalContext)

    const h2 = {
        fontSize: "3rem",
        fontWeight: "800",
        width: "100%"
    }
    const li = {
        fontSize: "18px",
        fontWeight: "600"
    }

    const textPositionStyles = {
        left: {
            textAlign: 'left',
        },
        right: {
            textAlign: 'right',
        },
    };

    const [slide, setSlide] = useState([])
    const [loading, setLoading] = useState(true); 
    useEffect(() => {
        const fetchSlide = async () => {
            try {
                const response = await axiosClient.get('slide/get')
                if (response.data.status) {
                    const formattedData = response.data.data.map(item => ({
                        id: item.id,
                        image: item.img || '',
                        title: item.heading || '',
                        formattedSubHeading: formatSubHeading(item.sub_heading || ''),
                        buttonText: item.button_text || 'Start Planning Your Event',
                        textPosition: item.text_position || 'left',
                    }));
                    setSlide(formattedData);
                    setLoading(false);
                } else {
                    console.error('Failed to fetch slides:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching slides:', error);
                setLoading(false);
            }
        };
        fetchSlide()
    }, [])

    // console.log("slide", slide)



    return (
        <section id="home" className="hero-block">
            {loading ? (
                <CarouselSkeleton />
            ) : (
                <Carousel>
                    {
                        slide.map(hero => {
                            return (
                                <Carousel.Item key={hero.id}>
                                    <div className="image-wrapper">
                                        <img
                                            loading='lazy'
                                            className="d-block w-100 sdkjhcskd"
                                            src={Config.baseUrl + "/" + hero.image}
                                            alt={"slide " + hero.id}
                                        />
                                        <div className="image-overlay"></div>
                                    </div>
                                    {hero.textPosition === 'left' ? (
                                        <Carousel.Caption className='carousel-caption'>
                                            <h2 className='carousel_heading' style={h2}>{hero.title}</h2>
                                            <div className="sub_heading" dangerouslySetInnerHTML={{ __html: hero.formattedSubHeading }}></div>
                                            {/* <button id="new_button_click" onClick={() => setShow(true)}>{hero.buttonText} <i className="fas fa-chevron-right"></i></button> */}
                                        </Carousel.Caption>
                                    ) : (
                                        <Carousel.Caption className='carousel-caption1'>
                                            <h2 className='carousel_heading_1' >{hero.title}</h2>
                                            <p className="sub_heading_1" dangerouslySetInnerHTML={{ __html: hero.formattedSubHeading }}></p>
                                            {/* <button id="new_button_click_1" onClick={() => setShow(true)}>{hero.buttonText} <i className="fas fa-chevron-right"></i></button> */}
                                        </Carousel.Caption>
                                    )}
                                    <div className='new_carousal_button'>
                                        <button id="new_button_click_1" onClick={() => setShow(true)} >Start your rental</button>
                                        
                                        <button id="new_button_click_1" > <Link to="/contact/us/:contactUs" >Talk to a Pro</Link> </button>
                                    </div>
                                </Carousel.Item>
                            );
                        })
                    }
                </Carousel>
            )}
        </section>
    );
}

export default Main;