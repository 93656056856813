import React, { useRef, useEffect, useState, useContext } from "react";
import "./header.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { userAction } from "../../redux/slices/userSlice";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../../src/assets/images/fvA.png";
import axiosClient from "../../axios-client";
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import { getWishcount } from "../../redux/slices/settingSlice";
import { FaAngleDown, FaRegHeart } from "react-icons/fa";
import { MdOutlineShoppingBag } from "react-icons/md";
import { VscThreeBars } from "react-icons/vsc";
import { FaBars } from "react-icons/fa";


import { Button, Menu, MenuItem, Fade, List, ListItem, ListItemText } from "@mui/material";

import "../../styles/bottom_navigate.css"



import MobileBottomNavbar from "./MobileBottomNavbar";
import { FaRegUser } from "react-icons/fa";
import { useQuery } from "react-query";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { PlanModalContext } from "../Layout/Layout";
import { NotificationManager } from "react-notifications";


const nav__links = [
  {
    path: "/",
    display: "Rental",
  },
  {
    path: "all/product",
    display: "\Services",
  },

  // {
  //   path: "",
  //   display: "Stage Equipment",
  // },
  {
    path: "/portfolio",
    display: "Portfolio",
  },
  {
    path: "/package",
    display: "Pre Made Package",
  },
  {
    path: "/aboutus",
    display: "About Us",
  },
  {
    path: "contact/us/ll",
    display: "Contact Us",
  },
];

const Header = () => {
  const dispatch = useDispatch();
  const headerRef = useRef(null);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const totalPrice = useSelector((state) => state.cart.totalAmount);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const wishcount = useSelector((state) => state.setting.wishcount);
  // const username = islogin && JSON.parse(userinfo).name;
  const basepath = useSelector((state) => state.setting.basepath);

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));


  const menuRef = useRef(null);
  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    dispatch(getWishcount());
  }, [])


  const [anchorEl, setAnchorEl] = useState(null)
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };




  const [anchorEl22, setAnchorEl22] = useState(null)
  const open22 = Boolean(anchorEl22);

  const handleClick22 = (event) => {
    setAnchorEl22(event.currentTarget);
  };

  const handleClose22 = () => {
    setAnchorEl22(null);
  };

  const userLogout = () => {
    handleClose22()
    axiosClient.post("logout").then(({ data }) => {
      dispatch(userAction.logout());
      localStorage.clear();
      NotificationManager.success('Successfully Logout')
      navigate("/login");
      window.location.reload();

    });
  };



  let [showSubcategory, setSubcategory] = useState(false);
  let [subcategory, setSubcatListegory] = useState([]);
  const categoryList = useQuery('categorylist', async () => await axiosClient.get('product/category').then(({ data }) => data));


  const menuToggle = () => menuRef.current.classList.toggle("active__menu");
  const navigateToCart = () => {
    navigate("/cart");
  };
  let [srcname, setsrcname] = useState();

  let [srcProductList, setSrcProductList] = useState([]);

  // console.log("srcname", srcname)

  let searchProduct = (name) => {
    setsrcname(name);
    axiosClient.get(`search/product?name=${name}`).then(({ data }) => {
      if (name) {
        setSrcProductList(data);
      } else {
        setSrcProductList([]);
      }
    });
  };

  let subcategoryset = (categoryId) => {
    axiosClient
      .get(`category/wise/subcategory?category_id=${categoryId}`)
      .then(({ data }) => {
        setSubcatListegory(data);
        if (data.length > 0) {
          setSubcategory(true);
        }
      });
  };

  let srcInputname = (name) => {
    setsrcname(name);
    setSrcProductList([]);
  };

  const handleCategory = (id) => {
    navigate(`all/product?cat=${id}`)
    handleClose();

  }

  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [toggleAnchorEl, setToggleAnchorEl] = useState(null);
  const navMenuRef = useRef(null);
  
  
  const getuserinfo = JSON.parse(localStorage.getItem("userDetails")) ||JSON.parse(localStorage.getItem('USER_DATA'));
  //const [userInfo, setUserInfo]= useState(JSON.parse(getuserinfo));

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleToggleClick = (event) => {
    setToggleAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleToggleClose = () => {
    setToggleAnchorEl(null);
    setMenuOpen(false);
  };



  let username = "";
  if (islogin && userinfo) {
    try {
      let userInfoObj;
      if (typeof userinfo === "string") {
        userInfoObj = JSON.parse(userinfo);
      } else {
        userInfoObj = userinfo;
      }
      
      username = userInfoObj.name;
    } catch (error) {
      console.error("Error parsing or accessing userinfo:", error);
    }
  }
  let userphoto = "";
  if (islogin && userinfo) {
    try {
      let userInfoObj;
      if (typeof userinfo === "string") {
        userInfoObj = JSON.parse(userinfo);
      } else {
        userInfoObj = userinfo;
      }
      
      userphoto = userInfoObj.photo;
    } catch (error) {
      console.error("Error parsing or accessing userinfo:", error);
    }
  }

  
//console.log("userphoto",getuserinfo);

  return (
    <>
      {/* Header Top Strip  */}
      <MobileBottomNavbar />
      <header className="header-top-strip  " style={{ border: "none" }}>
        <div className="header_top_home">
          <div className="container d-flex">
            <div className="col-md-6 d-flex justify-content-start " style={{ color: "#092143" }}>

              <a href="https://www.facebook.com/theDJPEOPLES/videos" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-facebook" style={{ marginRight: "10px", width: "26px", height: "26px" }}></i>
              </a>

              <a href="https://twitter.com/thedjpeoples" target="_blank" rel="noopener noreferrer">
                <i class="bi bi-twitter-x" style={{ marginRight: "10px", width: "26px", height: "26px" }}></i>
              </a>

              <a href="https://in.pinterest.com/DJPeoples/" target="_blank" rel="noopener noreferrer">
                <i class="bi bi-pinterest" style={{ marginRight: "10px", width: "26px", height: "26px" }}></i>
              </a>

              <a href="https://www.linkedin.com/company/unavailable/" target="_blank" rel="noopener noreferrer">
                <i class="bi bi-linkedin" style={{ marginRight: "10px", width: "26px", height: "26px" }}></i>
              </a>

              {/* <i class="bi bi-telegram" style={{ marginRight: "10px", width: "26px", height: "26px" }}></i> */}

            </div>

            {/* <div className="col-md-1 d-flex justify-content-start header_item"><i class="bi bi-envelope header_icon"></i>
              <p className="textitem">NEWSLETTER</p>
            </div> */}
            <div className="col-md-6 d-flex justify-content-end  saavshgsh">
              <Link to="contact/us/:contactUs" className="header_item2" >
              <p className="textitem" style={{marginRight:5}}>CONTACT US</p>
              </Link>
              <Link to="/faq">
              <p className="textitem">FAQS</p>
              </Link>
            </div>
            {/* <div className="col-md-1 d-flex justify-content-start header_item3 saavshgsh">
              
            </div> */}
          </div>
        </div>
      </header>

      {/* Header Middle Start  */}
      <header className="header-middle " >
        <div className="container">
          <div className="row align-items-center  gx-3 d-flex justify-content-between" style={{ width: "100%" }}>

            <div className="col-lg-2 col-md-4 logo_item">
              <div className="logo">
                <Link to={"/"}>
                  <img src={logo} alt="logo" />
                </Link>
              </div>
            </div>

            <div className="col-lg-6 col-md-5 d-flex search_bar">
              <Form className="d-flex align-items-center search-field" >
                <Form.Control
                  onChange={(e) => {
                    searchProduct(e.target.value);
                  }}
                  value={srcname}
                  type="search"
                  placeholder="Search for Product"
                  className="input_radius"
                  aria-label="Search"
                />
                {srcProductList.length > 0 && (
                  <ul className="srcitem">
                    {srcProductList.map((data, index) => (
                      <Link
                        to={`/shop/${data.id}`}
                        className="linkst"
                        onClick={() => srcInputname(data.name)}
                      >
                        <li className="srcItem">{data.name}</li>
                      </Link>
                    ))}
                  </ul>
                )}
                <div className='search_button'>
                  <i className="bi bi-search"></i>
                </div>
              </Form>
            </div>
            <div className="col-lg-3 col-md-5 details_bar" >
              <div>Miami: <span>(305) 330-1357</span></div>
              <div>FTL: <span>(305) 967-7422</span></div>
              <div className="header_mail">Email: <a  href="mailto:marc@marcvane.com">marc@marcvane.com</a></div>
              </div>

            <div className="col-lg-1 col-md-4 wish_cart">
              <div className='login_section'>
                <Nav.Link className='common_icon_cc'>
                  {/* <FaRegUser style={{fontSize:"20px"}}/> */}
                  {islogin ? (
                    <div
                      className="d-flex align-items-center "
                      to={"/login"}
                    >
                      {/* <div className="userimgdiv"><span className="userimg"><AiOutlineUser /></span></div> */}
                      <div className="dropdown">
                        <div>
                          <Button
                            id="fade-button2"
                            aria-controls={open22 ? 'fade-menu3' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open22 ? 'true' : undefined}
                            className="username"
                            onClick={handleClick22}
                          >
                           {getuserinfo && getuserinfo.photo !== null ?<img
                                src={`${basepath}/${getuserinfo.photo}`}
                                alt="Profile"
                               class="profile_images"
                               style={{margin:"0px 5px"}}
                              />: <FaRegUser size="20" style={{color:"#000"}}/> 
                            }
                            <span style={{color:"black"}}>{getuserinfo && getuserinfo.name}</span>
                            <span className="userdropicon"><i class="bi bi-chevron-down"></i></span>
                          </Button>
                        </div>
                      </div>

                    </div>
                  ) : (
                    <div className="common_icon_cc">
                      <Link to={"/login"}>
                        <p className=""> Login </p>
                      </Link>/
                      <Link to="/Signup">
                        <p className=""> Register </p>
                      </Link>
                    </div>

                  )}
                  <Menu
                    id="fade-menu3"
                    // MenuListProps={{
                    //   'aria-labelledby': 'fade-button2',
                    // }}
                    anchorEl={anchorEl22}
                    open={open22}
                    onClose={handleClose22}
                    TransitionComponent={Fade}
                  >
                    <Link
                      to={"/user/deshboard/1"}
                    >
                      <MenuItem onClick={handleClose22}>Profile</MenuItem>
                    </Link>

                    {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                    <MenuItem onClick={userLogout}>Logout</MenuItem>
                  </Menu>

                </Nav.Link>
                {/* <Link to={"/product/wishlist"}>
                  <FaRegHeart style={{ fontSize: "1rem" }}
                    onClick={navigateToCart}
                  />
                </Link> */}
                {/* <Nav.Link>
                <i class="bi bi-repeat common_icon_c"></i>

              </Nav.Link> */}


                <SimpleListMenu islogin={islogin} total={totalQuantity} />

                {/* <Nav.Link className='new_common_head'> $0.00</Nav.Link> */}
              </div>
            </div>

          </div>
        </div>
      </header>

      {/* Header Bottom Start  */}
      <header className="header-bottom">
        <div className="container">
          <div className="row align-items-center d-flex justify-content-between">
            <div className="col-lg-3 col-md-3 col-sx-3">
              <div className="dropdown">
                <div className="drpMenuItem custopdropdown d-flex align-items-center">
                  <Button
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    className="username"
                    onClick={handleClick}
                  >
                    <VscThreeBars style={{ fontSize: "18px" }} /> BROWSE CATEGORIES
                    <span className="userdropicon">
                      <FaAngleDown />
                    </span>
                  </Button>
                  <div className="menu-toggle-icon ml-auto" onClick={handleToggleClick}>
                    <FaBars />
                  </div>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    className="cartlistitem"
                  >
                    {categoryList.isLoading === false && Array.isArray(categoryList.data) && categoryList.data.map((value, index) => (
                      <MenuItem
                        className="categoryList__st itemstyle--set"
                        onClick={() => handleCategory(value.id)}
                        onMouseLeave={() => setSubcategory(false)}
                        style={{ width: '300px' }}
                        key={index}
                      >
                        <img className="categoryimgdown" src={`${basepath}/${value.image}`} alt="" />
                        <div>{value.name}</div>
                      </MenuItem>
                    ))}
                    {showSubcategory && (
                      <div className="subcategory__list__st"
                        onMouseEnter={() => setSubcategory(true)}
                        onMouseLeave={() => setSubcategory(false)}
                      >
                        <ul className="dwdf">
                          {subcategory.map((value, index) => (
                            <Link
                              to={`/products/list/${value.category_id}/${value.id}/0/0/0`}
                              className="liststyle"
                              key={index}
                            >
                              <li className="box d-flex itemstyle--set">
                                <span>{value.name} </span>
                              </li>
                            </Link>
                          ))}
                        </ul>
                      </div>
                    )}
                  </Menu>

                  <Menu
                    id="toggle-menu"
                    MenuListProps={{
                      'aria-labelledby': 'toggle-button',
                    }}
                    anchorEl={toggleAnchorEl}
                    open={menuOpen}
                    onClose={handleToggleClose}
                    TransitionComponent={Fade}
                    className="cartlistitem"
                  >
                    {nav__links.map((item, index) => (
                      <MenuItem
                        className="categoryList__st itemstyle--set"
                        onClick={handleToggleClose}
                        style={{ width: '300px' }}
                        key={index}
                      >
                        <NavLink
                          to={item.path}
                          className={(navClass) => (navClass.isActive ? "nav__active" : "")}
                        >
                          {item.display}
                        </NavLink>
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-md-8 col-sx-9">
              <div className={`menu-links ${menuOpen ? "menu-open" : ""}`} ref={navMenuRef}>
                <ul>
                  {nav__links.map((item, index) => (
                    <li className="nav__item" key={index}>
                      <NavLink
                        to={item.path}
                        className={(navClass) => (navClass.isActive ? "nav__active" : "")}
                      >
                        {item.display}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

// const SimpleBottomNavigation=()=>{
//   const [value, setValue] = React.useState('recents');

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (

//     <div class="navbar">
//     <a href="#home" class="active"><AiOutlineHome/> Home</a>
//     <a href="#news"><BsSuitHeart/> Wish List</a>
//     <a href="#contact"><AiOutlineShoppingCart/> Cart</a>
//     <a href="#contact"><BsPersonCircle/> Account</a>
//   </div>
//     );
// }


const SimpleListMenu = (props) => {
  const dispatch = useDispatch();
  const { islogin } = props;
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const basepath = useSelector((state) => state.setting.basepath);
  const open = Boolean(anchorEl);

  const cartItems = useSelector((state) => state.cart.cartItems);
  const getuserinfo = JSON.parse(localStorage.getItem("form_builderId"));

  const [cartitem, setCartitem]= useState('')
  // console.log(cartItems, "cartItems")

  const options = [
    'Show some love to MUI',
    'Show all notification content',
    'Hide sensitive notification content',
    'Hide all notification content',
  ];
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event,
    index,
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { show, setShow } = useContext(PlanModalContext)
  const [getIdform, setGetIdform] = useState();


  const getfomrData = async () => {
    try {
      await axiosClient.get('eventbuilder/get/id').then(resopnse => {
        setGetIdform(resopnse.data.data)
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getfomrData();
  }, []);


  const form_id = getIdform && getIdform.id;

  const dataitems = cartItems.map(item => ({
    id: item.id || null,
    unit: item.quantity || null
  }));

  const ItemData = {
    products: dataitems
  };


  useEffect(() => {
    if (form_id !== undefined) {
      userDetals();
    }
  }, [cartItems]);

  const getcartData = async () => {
    try {
      await axiosClient.get('user/cart').then(resopnse => {
        setCartitem(resopnse.data)
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getcartData();
  }, []);

  //console.log("ItemData",ItemData)

  const user_id = getuserinfo && getuserinfo.id;

 //console.log("user_id",user_id,form_id,getIdform);
  const userDetals = async () => {
    if (form_id !== undefined) {
      try {
        const response = await axiosClient.post(`eventbuilder/product/${user_id}`, ItemData);
        //console.log("response", response.data);
        getcartData();
      } catch (error) {
        console.log(error)
      }

    }
  }


 //console.log("cartitem" ,cartitem);



  return (
    <div>
      <List
        component="nav"
        aria-label="Device settings"
        
      // sx={{ bgcolor: 'background.paper' }}
      >
        <span
          // id="lock-button"
          // aria-haspopup="listbox"
          // aria-controls="lock-menu"
          // aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          <div className="cart__list" style={{ cursor: "pointer" }}>

            <div className="d-flex align-items-center gap-10 text-black position-relative">
              <img style={{width: 22}} src="https://img.icons8.com/?size=100&id=85383&format=png&color=000000"></img>
              {/* <MdOutlineShoppingBag style={{ fontSize: "20px" }} /> */}
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ padding: "5px" }}>
                {cartitem && cartitem.length}
              </span>
            </div>

          </div>
        </span>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >


        <div className="cartdropwidth">
          {cartitem && cartitem.length > 0 ? (
            cartitem.map((item, index) => (
              <MenuItem className="cartdwnsst" style={{ width: '300px', dispatch: "flex", flexWrap: 'wrap', gap: "2px", border: '1px solid rgb(243 236 236 / 45%)' }}>
                <div style={{ flexBasis: "30%", position: "relative" }}>
                  <span className="itmqty_sp">{item.quantity}</span>
                  <img
                    className="item__img"
                    src={`${basepath}/${item.imgUrl}`}
                    alt=""
                  />
                </div>
                <div style={{ flexBasis: "65%" }}>
                  {item.productName && item.productName.length > 15 ? item.productName.slice(0, 15) + '..' : item.productName}
                  <br />
                  <p className="price_value">{item.price * item.quantity}</p>
                </div>
              </MenuItem>
            ))
          ) : (
            <div className="empty_cart">

            </div>
          )}

          {/* {islogin ? (
            <Link to={"/review-package"} onClick={handleClose} className="w-100">
              <MenuItem className="bottomdivst d-flex justify-content-center" style={{
                background: "red", background: '#c22026', marginBottom: '-8px',
                color: 'white'
              }}>Checkout</MenuItem>
            </Link>
            ) : (
              <MenuItem    className="bottomdivst d-flex justify-content-center" style={{
                background: "red", background: '#c22026', marginBottom: '-8px',
                color: 'white'
              }}>Checkout</MenuItem>
            )} */}
          {cartitem && cartitem.length > 0 && (
            <Link to={"/checkout"} onClick={handleClose} className="w-100">
              <MenuItem className="bottomdivst d-flex justify-content-center" style={{
                background: "red", background: '#c22026', marginBottom: '-8px',
                color: 'white'
              }}>Checkout</MenuItem>
            </Link>
          )}
        </div>
      </Menu>
    </div >
  );
}


export default Header;
