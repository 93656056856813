import React from 'react'

function CommingSoon() {
  return (
    <div style={{display:"flex", justifyContent:"center",alignItems:"center", height:"100vh"}}>
      <h1>Coming Soon...</h1>
    </div>
  )
}

export default CommingSoon
