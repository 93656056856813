import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import "../../styles/product-details.css";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../redux/slices/cartSlice";
import axiosClient from "../../axios-client";
import pimg from "../../assets/images/arm-chair-02.jpg";
import Carousel from "react-multi-carousel";
import { getCurrencydata } from "../../redux/slices/settingSlice";
import { AiOutlineShoppingCart, AiOutlineShopping } from "react-icons/ai";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import { Config } from "../../Config";
import Tabs from "react-bootstrap/Tabs";
import Spinner from "react-bootstrap/Spinner";
import { PlanModalContext } from "../Layout/Layout";
import call1 from '../../assets/images/call1.jpg';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NotificationManager } from "react-notifications";



const ProductDetailsCart = (props) => {
  const dispatch = useDispatch("");
  const [id, setid] = useState(props.id);
  const url = window.location.href;
  const [offerId, setofferId] = useState(0);
  let [productDetals, setProductDetails] = useState([]);
  let [productDiscount, setProductDiscount] = useState(0);
  // let [relatedProducts, setRelatedProducts] = useState([]);
  let [productImg, setProductImages] = useState([]);
  let [productmainImg, setProductMainImages] = useState("");
  let [showImg, setshowImg] = useState("");
  let [color, setColor] = useState(false);
  let [size, setSize] = useState(false);
  let [description, setDescription] = useState("");
  let [heading, setHeading] = useState("");
  let [delivery, setDelivery] = useState("");
  const [imagesToShow, setImagesToShow] = useState(3);
  const [retailPrice, setRetailPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [productName, setProductName] = useState("");
  const [tabData, setTabData] = useState({
    Delivery: "",
    Outdoor: "",
    Warehouse_Pickup: "",
    Warning: "",
  });

  let [choose_color, setchoose_color] = useState("");
  let [choose_size, setchoose_size] = useState("");
  const currencySymbol = useSelector((state) => state.setting.currency);
  const basepath = useSelector((state) => state.setting.basepath);
  const islogin = useSelector((state) => state.user.isLogin);
  const guestInfo = useSelector((state) => state.user.guestInfo);
  const { show, setShow } = useContext(PlanModalContext);
  const navigate = useNavigate();
  const [subProduct, setSubProduct] = useState([]);
  const [subproductName, setSubProductName] = useState();
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState('');
  const [allComments, setAllComments] = useState([]);


  //console.log("subProduct", subProduct);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 10,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const [qty, setQty] = useState(1);

  useEffect(() => {
    setColor(false);
    setSize(false);
    dispatch(getCurrencydata());
    axiosClient.get(`product/details?id=${props.id}`).then(({ data }) => {
      console.log("data", data);
      data.color && setColor(data.color.split(","));
      data.size && setSize(data.size.split(","));
      setProductDetails(data);
      setProductMainImages(data.image_path);
      setshowImg(data.image_path);
      setProductImages(data.product_image);
      setProductDiscount(data.discount);
      setDescription(data.description);
      setHeading(data.heading);
      setDelivery(data.delivery_cost);
      setRetailPrice(data.retail_price);
      setSalePrice(data.current_sale_price);
      setDiscount(data.discount);
      setImagesToShow(3);
      setProductName(data.name);
      setSubProduct(Array.isArray(data.sub_product) ? data.sub_product : []);

      const filteredData = {};
      [
        "mess_deli_insta",
        "mess_outdoor",
        "mess_warehouse",
        "mess_warning",
      ].forEach((key) => {
        if (data[key]) {
          filteredData[key] = data[key];
        }
      });
      setTabData(filteredData);
    });

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [url]);

  const qtyChange = (typ2) => {
    if (typ2 === "plus") {
      setQty((pre) => pre + 1);
    }
    if (typ2 === "neg") {
      setQty((pre) => (pre <= 1 ? 1 : pre - 1));
    }
  };
  let totalDiscount = 0;
  var discountPrc = 0;

  var discountPercentageCal = 0;
  if (offerId > 0) {
    if (productDetals.offer_type == 0) {
      totalDiscount = Math.round(productDetals.offer_amount);
      discountPrc = Math.round(totalDiscount);
    }
    if (productDetals.offer_type == 1) {
      let totaldis =
        (productDetals.current_sale_price * productDetals.offer_amount) / 100;
      totalDiscount = Math.round(totaldis);
      discountPrc = Math.round(productDetals.offer_amount);
    }
  } else {
    if (productDetals.discount > 0) {
      if (productDetals.discount_type == 0) {
        totalDiscount = Math.round(productDetals.discount);
        discountPrc = Math.round(totalDiscount);
      }
      if (productDetals.discount_type == 1) {
        let totaldis =
          (productDetals.current_sale_price * productDetals.discount) / 100;
        totalDiscount = Math.round(totaldis);
        discountPrc = Math.round(totalDiscount);
      }
    }
  }
  const addToCart = (item, type) => {
    dispatch(
      cartActions.addItem({
        id: item.id,
        productName: item.name,
        price: item.current_sale_price - discountPrc,
        imgUrl: item.image_path,
        offerId: offerId,
        color: color,
        size: size,
      })
    );
    let data = { id: item.id, qty: qty };
    dispatch(cartActions.itemIncDic(data));

    type == "buy" && navigate("/checkout");
    type != "buy" && NotificationManager.success("product added successfully");
  };

  const ITEMS_TO_SHOW = 4;
  const [itemsToDisplay, setItemsToDisplay] = useState(ITEMS_TO_SHOW);

  const handleShowMore = () => {
    setItemsToDisplay((prevItemsToDisplay) =>
      prevItemsToDisplay === ITEMS_TO_SHOW ? subProduct.length : ITEMS_TO_SHOW
    );
  };

  const handleShowMoreImages = () => {
    setImagesToShow(imagesToShow + 3);
  };

  const tabIcons = {
    mess_deli_insta: "bi-truck",
    mess_outdoor: "bi-cloud-sun",
    mess_warehouse: "bi-building",
    mess_warning: "bi-exclamation-circle",
  };

  const tabNames = {
    mess_deli_insta: "Delivery",
    mess_outdoor: "Outdoor",
    mess_warehouse: "Warehouse Pickup",
    mess_warning: "Warning",
  };

  function splitStringWithLineBreaks(str) {
    return str.split(/\r\n/);
  }

  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(3);


  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);
  const handleHide = () => setVisible(false);
  const handleVisible = () => setVisible(true);
  
  const showMoreItems = () => {
    setItemsToShow((prev) => prev + 3);
  };

  const handleClick = (name) => {
    setSubProductName(name);
    setSubProduct([]);
  };
  const seeprodut = (product) => {
    console.log(product);
  };

  const truncateName = (name, maxLength = 18) => {
    if (name.length <= maxLength) {
      return name;
    }
    return name.slice(0, maxLength) + "...";
  };

  // Retrieve comments from localStorage on component mount
  useEffect(() => {
    const storedComments = JSON.parse(localStorage.getItem('comments')) || [];
    setAllComments(storedComments);
  }, []);


  const userData =  JSON.parse(localStorage.getItem("USER_DATA"));
  const initialFormState = {
    name: userData && userData.name || '',
    email: userData && userData.email || '',
    contact: userData && userData.phone || '',
    qus: ''
  }

  const initialFormStatefeedback = {
    title:'',
    name: userData && userData.name || '',
    email: userData && userData.email || '',
    contact: userData && userData.phone || '',
    feedback: '',
    rating:'',
  }

  const [formData, setFormData] = useState(initialFormState);
  const [feedbackformData, setFeedbackformData] = useState(initialFormStatefeedback);
  const [waiting, setWaiting] = useState(false);
  const [getanswer, setGetanswer] = useState('');
  const [getfeedback, setGetfeedback] = useState('');



  const getAnswer = async() =>{
      axiosClient.get(`qus_ans/get/${props.id}`).then((anserdata)=>{
        setGetanswer(anserdata.data);
      })
  }
  const getFeedback = async() =>{
    axiosClient.get(`feedback/get/${props.id}`).then((feedbackdata)=>{
      setGetfeedback(feedbackdata.data);
    })
}
  useEffect(()=>{
    getAnswer();
    getFeedback();
  },[props.id])

  
  
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };




  const handleSubmit = async(e) => { 
    e.preventDefault();
    setWaiting(true);
      const datainfo = {
        product_id:props.id,
        name:formData.name,
        email:formData.email,
        contact:formData.contact,
        qus:formData.qus

      }
    try {
      const response =  await axiosClient.post(`qus_ans/store`,datainfo,{
        headers: {
          "Content-Type": "application/json",
        },
      });
      NotificationManager.success("your Question have sent successfuly ");
      console.log("Response:",response);
    } catch (error) {
      console.log(error);
    }
    
    handleClose();
    setFormData(initialFormState);
    setWaiting(false)
  };


  const onhandleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackformData({
      ...feedbackformData,
      [name]: value
    });
  };

  const handleSubmitfeedback = async(e) => { 
    e.preventDefault();
    setWaiting(true);
      const datainfo = {
        product_id:props.id,
        name:feedbackformData.name,
        email:feedbackformData.email,
        contact:feedbackformData.contact,
        title:feedbackformData.title,
        rating:feedbackformData.rating,
        feedback:feedbackformData.feedback,

      }
    try {
      const response =  await axiosClient.post(`feedback/store`,datainfo,{
        headers: {
          "Content-Type": "application/json",
        },
      });
      NotificationManager.success("your feedback have added ");
      getFeedback();
      console.log("Response:",response);
    } catch (error) {
      console.log(error);
    }
    
    handleHide();
    setFeedbackformData(initialFormStatefeedback);
    setWaiting(false)
  };

  return (
    <section className="mb-4 pt-4">
      <Container>
        <div className="bg-white shadow-sm p-4">
          <Row>
            <Col lg="5" col md="12">
              <div className="mb-4 mb-lg-0 imgdiv">
                {loading ? (
                  <div className="spinner-container">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                    <div className="image_logo_positions">
                  <img
                    loading='lazy'
                    className="product__img"
                    src={`${basepath}/${showImg}`}
                    alt=""
                  />
                  {/* <img src={fr} className="image_logo"/> */}
                  </div>

                )}
              </div>
              {!loading && (
                <div className="product_img_main">
                  <div className="product_item_imgdiv">
                    <img
                      loading='lazy'
                      onClick={() => setshowImg(productmainImg)}
                      className="productitem"
                      src={`${basepath}/${productmainImg}`}
                      alt=""
                    />
                    
                  </div>
                  {productImg?.slice(0, imagesToShow).map((imgData, index) => (
                    <div className="product_item_imgdiv" key={index}>
                      <img
                        loading='lazy'
                        onClick={() => setshowImg(imgData.image)}
                        className="productitem"
                        src={`${basepath}/${imgData.image}`}
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              )}
              {(productImg ? productImg.length : 0) > 0 &&
                imagesToShow < (productImg ? productImg.length : 0) && (
                  <div
                    onClick={handleShowMoreImages}
                    className="show-more-button"
                  >
                    Show {(productImg ? productImg.length : 0) - imagesToShow}{" "}
                    more
                  </div>
                )}


            </Col>

            <Col lg="7" md="12">
              <div>
                <h1 className="product_name">{productName}</h1>
                <h5 className="product_heading">{heading}</h5>
                <h2 className="product_des">{description}</h2>
                <div className="product_detail">
                  <div className="product_price">
                    <h3 className="mb-2" style={{display:"flex", justifyContent:"space-around", alignItems:"center"}}>
                    <span style={{fontSize:"1.5rem", fontWeight:"600"}}>{currencySymbol} ${Number(salePrice).toFixed(2)}</span>
                    <span style={{display:"flex", alignItems:"center"}}><strike style={{fontSize:"1.25rem",fontWeight:"500"}}> {currencySymbol} ${Number(retailPrice).toFixed(2)} </strike>  </span>
                    <span style={{fontSize:"1.25rem", fontWeight:"600", color:"red"}}>{Number(discount).toFixed(0)}% off</span>
                    </h3>
                    <p>* Estimated Delivery & Installation ${delivery}</p>
                  </div>
                  {guestInfo !== null ? (
                    <div className="product_devli">
                      <button
                        className="btn_dev"
                        onClick={() => addToCart(productDetals, "cartadd")}
                      >
                        <AiOutlineShopping className="btn_dev_icon" />
                        Add to Cart
                      </button>

                      <button
                        className="btn_pick"
                        onClick={() => addToCart(productDetals, "buy")}
                      >
                        <AiOutlineShoppingCart className="btn_dev_icon" />
                        Buy Now
                      </button>
                    </div>
                  ) : (
                    <div className="product_devli">
                      <button
                        className="btn_dev"
                        onClick={() => setShow(true)}
                      >
                        <AiOutlineShopping className="btn_dev_icon" />
                        Add to Cart
                      </button>

                      <button
                        className="btn_pick"
                        onClick={() => setShow(true)}
                      >
                        <AiOutlineShoppingCart className="btn_dev_icon" />
                        Buy Now
                      </button>
                    </div>
                  )}
                </div>
                <div className="contact_small_tag">
                  <div className="inner_cantact">
                  <img src={call1} className="user_images_contact"/>
                  <div className="information_sections">
                    <div className="conatc_heading">Do you need help selecting your equipment?</div>
                    <div className="contact_sub_heading">Give us a call for assistance.</div>
                    <div className="contact_number"><strong>Contact :- </strong><strong> (954) 289-4907</strong></div>
                  </div>
                  </div>
                  
                </div>
                
                {/* {subProduct.length > 0 ? <div className="product_info">
                  <i class="bi bi-question-circle product_info_icon"></i>
                  <p className="product_inculde">Included with this Product:</p>
                </div> :null} */}
                <ul className="product-list">
                  {Array.isArray(subProduct) &&
                    subProduct
                      .slice(0, itemsToDisplay)
                      .map((product, index) => (
                        <Link
                          to={`/shop/${product.product.id}`}
                          className="product-item-link"
                          onClick={() => handleClick(product.product.name)}
                        >
                          <li key={index} className="product-item">
                            <div className="product_item_info">
                              <img
                                loading='lazy'
                                src={
                                  Config.baseUrl +
                                  "/" +
                                  product.product.image_path
                                }
                                alt={product.name}
                                className="product-image"
                                style={{ height: "50px", width: "50px" }}
                              />
                              <p className="product-name">
                                {truncateName(product.product.name)}
                              </p>
                            </div>
                            <p className="product-quantity">
                              {product.unit} Units
                            </p>
                          </li>
                        </Link>
                      ))}
                </ul>
                {Array.isArray(subProduct) &&
                  subProduct.length > ITEMS_TO_SHOW && (
                    <div onClick={handleShowMore} className="show-more-button">
                      {itemsToDisplay === ITEMS_TO_SHOW
                        ? `View ${subProduct.length - ITEMS_TO_SHOW
                        } more included product.`
                        : "Show Less"}
                    </div>
                  )}
              </div>

              <Tabs className="tab-panel">
                {Object.entries(tabData).map(([key, value]) => (
                  <Tab
                    key={key}
                    eventKey={key}
                    title={
                      <>
                        <i className={`bi ${tabIcons[key]} tab-title-icon`}></i>
                        <span>{tabNames[key]}</span>
                      </>
                    }
                    className="tab-content"
                  >
                    <>
                      {key.startsWith("mess_") ? (
                        <>
                          <i className={`bi ${tabIcons[key]} btn_dev_icon`}></i>
                          <span>{splitStringWithLineBreaks(value)[0]}</span>
                        </>
                      ) : (
                        <span>{value}</span>
                      )}
                      {splitStringWithLineBreaks(value).length > 1 && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              "<br/>" + splitStringWithLineBreaks(value)[1],
                          }}
                        ></div>
                      )}
                    </>
                  </Tab>
                ))}
              </Tabs>
            </Col>
            <Col lg="6" md="12">
            <div className="feedback_section">

            {islogin ?  <button type="submit" className="btn_dev" onClick={handleVisible}>Add Feedback</button> :<div className="user_feedback">User Feedback</div>}
                
<div>
      {Array.isArray(getfeedback) &&
        getfeedback.slice(0, itemsToShow).map((list, index) => {
          return (
            <div className="ans_qes" key={index}>
              <div className="question_type">
                <span
                  style={{backgroundColor:list.rating === 1? '#ff6161': list.rating === 2? '#ff9f00': '#388e3c',padding: 3,borderRadius: 5,color: '#fff',marginRight: 5, fontSize:13}}>
                  {list.rating}
                  <i
                    style={{ color: '#fff', fontSize: 12, paddingLeft: 2 }}
                    className="bi bi-star-fill"
                  ></i>
                </span>
                {list.title}
              </div>
              <div className="answer_type">{list.feedback}</div>
              <div className="user_name">{list.name}</div>
            </div>
          );
        })}

      {/* Show the button only if there are more items to show */}
      {itemsToShow < getfeedback.length && (
        <button className="showmore" onClick={showMoreItems}>Show More</button>
      )}
    </div>
         </div>
            </Col>
            <Col lg="6" md="12">
            <div className="comment_form">
                 <button type="submit" className="btn_dev" onClick={handleShow}>Ask Question</button>
                {Array.isArray(getanswer) && getanswer.slice(0, itemsToShow).map((list)=>{
                  return(
                  <div className="ans_qes">
                    <h5 className="question_type"> Que: {list.qus}</h5>
                    <div className="answer_type">Ans: {list.ans}</div>
                    <div className="user_name">{list.name}</div>
                  </div>
                )})}
              {itemsToShow < getanswer.length && (
        <button className="showmore" onClick={showMoreItems}>Show More</button>
      )}
            </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Modal 
      show={open} 
      onHide={handleClose} size="md"
      aria-labelledby="contained-modal-title-vcenter" 
      centered>
        <Modal.Header closeButton>
          <Modal.Title>Ask Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
          <form onSubmit={handleSubmit}>
      <input
        className="aske_question"
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Name"
      />
      <input
        className="aske_question"
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Email"
      />
      <input
        className="aske_question"
        type="number"
        name="contact"
        value={formData.contact}
        onChange={handleChange}
        placeholder="Number"
      />
      <textarea
        className="aske_question"
        rows={2}
        name="qus"
        value={formData.qus}
        onChange={handleChange}
        placeholder="Your Question"
      />
      <button className="btn_dev" type="submit" disabled={waiting}>{waiting ? <Spinner animation="border" variant="secondary" /> : 'Send'}</button>
    </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal 
      show={visible} 
      onHide={handleHide} size="md"
      aria-labelledby="contained-modal-title-vcenter" 
      centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
          <form onSubmit={handleSubmitfeedback}>
    <div class="rate">
        <input type="radio" id="star5" onChange={onhandleChange} name="rating" value="5" />
        <label for="star5"  >5 stars</label>
        <input type="radio" id="star4" name="rating" onChange={onhandleChange} value="4" />
        <label for="star4"  >4 stars</label>
        <input type="radio" id="star3" name="rating" onChange={onhandleChange} value="3" />
        <label for="star3"  >3 stars</label>
        <input type="radio" id="star2" name="rating" onChange={onhandleChange} value="2" />
        <label for="star2"  >2 stars</label>
        <input type="radio" id="star1" name="rating" onChange={onhandleChange} value="1" />
        <label for="star1" >1 star</label>
      </div>
          <input
        className="aske_question"
        type="text"
        name="title"
        value={feedbackformData.title}
        onChange={onhandleChange}
        placeholder="Title"
      />
      <input
        className="aske_question"
        type="text"
        name="name"
        value={feedbackformData.name}
        onChange={onhandleChange}
        placeholder="Name"
      />
      <input
        className="aske_question"
        type="email"
        name="email"
        value={feedbackformData.email}
        onChange={onhandleChange}
        placeholder="Email"
      />
      <input
        className="aske_question"
        type="number"
        name="contact"
        value={feedbackformData.contact}
        onChange={onhandleChange}
        placeholder="Number"
      />
      <textarea
        className="aske_question"
        rows={2}
        name="feedback"
        value={feedbackformData.feedback}
        onChange={onhandleChange}
        placeholder="feedback"
      />
    
      <button className="btn_dev" type="submit" disabled={waiting}>{waiting ? <Spinner animation="border" variant="secondary" /> : 'Send'}</button>
    </form>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default ProductDetailsCart;
